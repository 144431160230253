<div class="class-container">
  <div class="class-section">
    <div class="class-box">
      <app-class-detail></app-class-detail>
    </div>
<!--
    <div *ngIf="!isNew" class="class-box">
      <app-member-list></app-member-list>
    </div>
-->
  </div>
  <div *ngIf="!isNew" class="class-section">
    <div class="class-box">
      <div class="editor">
        <div>
          <button id="button-toggle-students" class="editor-button btn btn-icon-primary"
                  placement="bottom-start" ngbTooltip="Schüler zeigen / verbergen"
                  (click)="showStudents = !showStudents">Schüler
            <i-bs *ngIf="showStudents" name="caret-down-fill" class="btn-icon"></i-bs>
            <i-bs *ngIf="!showStudents" name="caret-right-fill" class="btn-icon"></i-bs>
          </button>
        </div>
        <div *ngIf="showStudents">
          <app-class-students-list></app-class-students-list>
        </div>
      </div>
    </div>
  </div>
</div>
