<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col" style="width: 25%"
        class="table-header col-one">
      Kursname
    </th>
    <th scope="col" style="width: fit-content(50%)"
        class="table-header">
      Beschreibung
    </th>
    <th scope="col" style="width: 50%;" class="table-header">
      <div class="registration-header">
        <div class="registration-header-item">angemeldet</div>
        <div class="registration-header-last-item">
          <button id="button-expand-all-student-lists" class="editor-button btn btn-icon btn-icon-secondary"
                  placement="bottom-left" ngbTooltip="{{collapsedAll ? 'alle aufklappen' : 'alle einklappen'}}"
                  (click)="switchCollapsedAll()">
            <i-bs *ngIf="collapsedAll" name="arrows-expand"></i-bs>
            <i-bs *ngIf="!collapsedAll" name="arrows-collapse"></i-bs>
          </button>
        </div>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let exCourse of scheduledCourses">
    <td class="col-one">{{exCourse.course?.name}}</td>
    <td>{{exCourse.course?.description}}</td>
    <td>
      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="getCollapsed(exCourse.courseId)">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="registration-student-list-button">Schüler:innen</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <app-registration-student-list [scheduledCourseId]="exCourse.getId()"></app-registration-student-list>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
