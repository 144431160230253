import {Injectable} from '@angular/core';
import {Navigationbar} from "./navigationbar";
import {Role} from "../../../authentication/models/role";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {MenuDropdown} from "../menu/menu-dropdown";
import {MenuItemLink} from "../menu/menu-item-link";
import {MenuItemSeparator} from "../menu/menu-item-separator";
import {LogonState} from "../../../authentication/models/logon-state";
import {LoginInformation} from "../../../authentication/services/login-information";

@Injectable({
  providedIn: 'root'
})
export class NavigationbarService {

  private id: number = 0;

  private navigationBar: Navigationbar;


  constructor(private loginInformationService: LoginInformationService) {
    this.navigationBar = this.createNavigationBar();
  }

  private createNavigationBar(): Navigationbar {
    let navigationbar = new Navigationbar('/assets/img/logo.png', '/assets/img/logo-ganztag-tr.png');
    // Planung
    navigationbar.addMenu(new MenuDropdown(this.id++, 'Planung', [
      new MenuItemLink(navigationbar, this.id++, 'Schwarzes Brett', 'board-view', LogonState.signedIn, Role.member),
      new MenuItemLink(navigationbar, this.id++, 'Neuer Tag', 'new-schedule', LogonState.signedIn, Role.admin),
      new MenuItemLink(navigationbar, this.id++, 'Geplante Tage', 'schedules', LogonState.signedIn, Role.admin)
      ]));
    // Schule
    navigationbar.addMenu(new MenuDropdown(this.id++, 'Schule', [
      new MenuItemLink(navigationbar, this.id++, 'Klassen', 'classes', LogonState.signedIn, Role.admin),
      new MenuItemLink(navigationbar, this.id++, 'Lehrkräfte', 'tutors', LogonState.signedIn, Role.admin),
      new MenuItemLink(navigationbar, this.id++, 'Kurse', 'courses', LogonState.signedIn, Role.admin)
    ], Role.admin));

    // Benutzer
    let that = this;
    const userMenu = new class extends MenuDropdown {
      override init() {
        that.loginInformationService.observeLoginInformation().subscribe(loginInformation => {
          this.title = that.getUserMenuTitle(loginInformation);
        });
      }
    }(this.id++, this.getUserMenuTitle(this.loginInformationService.getCurrenLoginInformation()),
      [
        new MenuItemLink(navigationbar, this.id++, 'Profil', 'profile', LogonState.signedIn),
        new MenuItemLink(navigationbar, this.id++, 'Passwort ändern', 'change-password', LogonState.signedIn),
        new MenuItemLink(navigationbar, this.id++, 'Passwort bestätigen', 'confirm-new-password', LogonState.signedOut),
        new MenuItemSeparator(this.id++),
        new MenuItemLink(navigationbar, this.id++, 'Anmelden', 'login', LogonState.signedOut),
        new MenuItemLink(navigationbar, this.id++, 'Abmelden', 'logout', LogonState.signedIn)
      ]);
    navigationbar.addMenu(userMenu);
    // Und die Navigationbar zurückgeben
    return navigationbar;
  }

  private getUserMenuTitle(loginInformation: LoginInformation) {
    return loginInformation.user && loginInformation.user.username && loginInformation.user.username !== ''
      ? `Benutzer (${loginInformation.user.username})`
      : 'Benutzer';
  }

  public getNavigationBar(): Navigationbar {
    return this.navigationBar;
  }

}
