import {NgModule} from '@angular/core';
import {AppCommonModule} from '../app-common.module';
import {LocalDatePipe} from './local-date-pipe';

@NgModule({
  declarations: [LocalDatePipe],
  exports: [
    LocalDatePipe
  ],
  imports: [
    AppCommonModule
  ]
})
export class DateModule { }
