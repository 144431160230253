import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputFieldComponent} from './input-field/input-field.component';
import {FormsModule} from '@angular/forms';
import {AppCommonModule} from '../app-common.module';

@NgModule({
  declarations: [InputFieldComponent],
  exports: [
    InputFieldComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppCommonModule
  ]
})
export class ComponentsModule { }
