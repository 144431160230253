<div class="editor">
  <!-- -->
  <div class="editor-row">
    <!-- Titel -->
    <div class="editor-header">
      Anmeldungen
    </div>
  </div>
  <!-- -->
  <div class="editor-row">
    <!-- date read-only -->
    <div class="editor-lable">
      <label for="input-date-ro">Datum:</label>
    </div>
    <div id="input-date-ro" class="editor-input editor-readonly">{{schedule.date | localDate}}</div>
  </div>
  <div>
    <div ngbAccordion>
      <!-- Bereich für die Kurse -->
      <div ngbAccordionItem [collapsed]="false">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="registration-sublist-button">Kurse</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <app-registration-course-list></app-registration-course-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="editor-row">
      <button id="button-back" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Zurück zur Liste"
              (click)="onGoToSchedule()">
        <i-bs name="caret-left-fill"></i-bs>
      </button>
  </div>
  <div *ngIf="errorMessage" class="editor-row">
    <ngb-alert [type]="'warning'" (closed)="closeError()">{{errorMessage}}</ngb-alert>
  </div>
  <div *ngIf="successMessage" class="editor-row">
    <ngb-alert [type]="'success'" (closed)="closeSuccess()">{{successMessage}}</ngb-alert>
  </div>
</div>
