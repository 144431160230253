<div class="app-body">
  <div class="app-header">
    <app-navigationbar></app-navigationbar>
  </div>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
  <div class="app-footer">
    <app-footer></app-footer>
  </div>
</div>
