import {Pipe, PipeTransform} from '@angular/core';
import {DateTimeFormatter, LocalDate, LocalDateTime, LocalTime} from '@js-joda/core';

/**
 * Mit der localDate-Pipe kann ein Wert von js-joda LocalDate, LocalTime bzw. LocalDateTime
 * dargestellt werden. Für alle Typen - LocalDate, LocalDateTime und LocalTime - besteht die
 * Möglichkeit, über ein vorgefertigtes Pattern oder ein eigenes Pattern die
 * Darstellung anzupassen
 *
 * Von Haus aus unterstützt diese Pipe aktuell nur die Darstellung in deutschem Format.
 *
 * <ul>
 *   <li>Reine Datumswerte werden dabei einfach mit einem Pattern wie 23.02.2021 dargestellt.
 *   </li>
 *   <li>Reine Zeitwerte können wie folgt dargestellt werden:
 *     <ul>
 *       <li><code>time-short</code>: Darstellung mit Stunden und Minuten (z.B. 23:59),
 *       </li>
 *       <li><code>time-long</code>: Darstellung mit Stunden, Minuten und Sekunden (z.B. 23:59:58),
 *       </li>
 *       <li><code>time-precision</code>: Darstellung mit Stunden, Minuten, Sekunden und
 *          Bruchteilen von Sekunden (z.B. 23:59:58,123),
 *       </li>
 *     </ul>
 *   </li>
 *   <li>Gemischte Datums- und Zeitwerte können wie folgt dargestellt werden:
 *     <ul>
 *       <li><code>date-time-short</code>: Darstellung mit Datum, gefolgt von Stunden und Minuten (z.B. 13.07.1987 23:59),
 *       </li>
 *       <li><code>date-time-long</code>: Darstellung mit Datum, gefolgt von Stunden, Minuten und Sekunden
 *          (z.B. 13.07.1987 23:59:58),
 *       </li>
 *       <li><code>date-time-precision</code>: Darstellung mit Datum, gefolgt von Stunden, Minuten, Sekunden und
 *          Bruchteilen von Sekunden (z.B. 13.07.1987 23:59:58,123),
 *       </li>
 *     </ul>
 *   </li>
 * </ul>
 */
@Pipe({name: 'localDate'})
export class LocalDatePipe implements PipeTransform {

  private readonly PATTERN_DATE: string = 'dd.MM.yyyy';
  private readonly FORMAT_DATE: DateTimeFormatter
    = DateTimeFormatter.ofPattern(this.PATTERN_DATE);

  private readonly PATTERN_DATE_TIME_SHORT: string = 'dd.MM.yyyy HH:mm';
  private readonly PATTERN_DATE_TIME_LONG: string = 'dd.MM.yyyy HH:mm:ss';
  private readonly PATTERN_DATE_TIME_PRECISION: string = 'dd.MM.yyyy HH:mm:ss,SSS';

  private readonly PATTERN_TIME_SHORT: string = 'HH:mm';
  private readonly PATTERN_TIME_LONG: string = 'HH:mm:ss';
  private readonly PATTERN_TIME_PRECISION: string = 'HH:mm:ss,SSS';

  transform(value: LocalDate | LocalDateTime | LocalTime | undefined, pattern?: string): string {
    if (!value) {
      return '';
    }
    if (value instanceof LocalDate) {
      return value.format(this.FORMAT_DATE);
    }
    if (value instanceof LocalDateTime) {
      return this.formateDateTime(value, pattern ? pattern : this.PATTERN_DATE_TIME_SHORT);
    }
    if (value instanceof LocalTime) {
      return this.formatTime(value, pattern? pattern : this.PATTERN_TIME_SHORT);
    }
    return '';
  }

  private formateDateTime(value: LocalDateTime, pattern: string): string {
    let formatPattern: string = this.PATTERN_DATE_TIME_SHORT;
    if (pattern) {
      switch (pattern) {
        case 'date-time-precision':
          formatPattern = this.PATTERN_DATE_TIME_PRECISION;
          break;
        case 'date-time-long':
          formatPattern = this.PATTERN_DATE_TIME_LONG;
          break;
        case 'date-time-short':
        case 'date-time-standard':
          formatPattern = this.PATTERN_DATE_TIME_SHORT;
          break;
        default:
          formatPattern = pattern;
      }
    }
    return value.format(DateTimeFormatter.ofPattern(formatPattern));
  }

  private formatTime(value: LocalTime, pattern: string) {
    let formatPattern: string = this.PATTERN_TIME_SHORT;
    if (pattern) {
      switch (pattern) {
        case 'time-precision':
          formatPattern = this.PATTERN_TIME_PRECISION;
          break;
        case 'time-long':
          formatPattern = this.PATTERN_TIME_LONG;
          break;
        case 'time-short':
        case 'time-standard':
          formatPattern = this.PATTERN_TIME_SHORT;
          break;
        default:
          formatPattern = pattern;
      }
    }
    return value.format(DateTimeFormatter.ofPattern(formatPattern));
  }

}
