<div class="board-header">
  <div class="board-header-control">
    <div class="board-header-text">
      Datum:
    </div>
  </div>
  <div ngbDropdown class="board-header-control">
    <button class="btn board-button board-button-border board-button-wide" id="date-select-button"
            ngbDropdownToggle>{{getSelectedDate()}}</button>
    <div ngbDropdownMenu aria-labelledby="date-select-button" class="board-button-drop-down">
      <div *ngFor="let date of getAvailablePlannedDates()">
        <button class="board-button-drop-down-content" ngbDropdownItem
                (click)="updatePlannedDate(date)">{{date}}</button>
      </div>
    </div>
  </div>
  <div class="board-header-button">
    <button class="btn btn-icon btn-icon-primary board-button-small" id="scale-plus" (click)="scaleUp()" [disabled]="isNotScaleUpPossible()">
      <i-bs name="plus-square-fill"></i-bs>
    </button>
  </div>
  <div class="board-header-control">
    <div class="board-header-text board-header-text-fix">
      {{scale}}%
    </div>
  </div>
  <div class="board-header-button">
    <button class="btn btn-icon btn-icon-primary board-button-small" id="scale-minus" (click)="scaleDown()" [disabled]="isNotScaleDownPossible()">
      <i-bs name="dash-square-fill"></i-bs>
    </button>
  </div>
  <div class="board-header-button">
    <button class="btn btn-icon btn-icon-primary board-button-small" id="scale-reset" (click)="scale100()">
      <i-bs name="icon1-square-fill"></i-bs>
    </button>
  </div>
</div>
<div class="board-container">
  <div [ngStyle]="boardStyle">
  <div class="board">
    <ko-stage [config]="configStage" #stage>
      <ko-layer #layer>
      </ko-layer>
    </ko-stage>
  </div>
  </div>
</div>
