export class Class {
  id?: number;
  grade: string;
  form: string;

  constructor(id: number | undefined, grade: string, form: string) {
    this.id = id;
    this.grade = grade;
    this.form = form;
  }

  getDisplayName(): string {
    return this.grade + ' ' + this.form;
  }
}
