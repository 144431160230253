import {Component, Input, OnInit} from '@angular/core';
import {MenuButton} from "./menu-button";
import {LoginInformationService} from "../../../authentication/services/login-information.service";

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent implements OnInit {

  @Input() menuButton?: MenuButton;

  constructor(private loginInformationService: LoginInformationService) {
    this.menuButton = undefined;
  }

  ngOnInit(): void {
  }

  onClick(event: Event) {
    event.preventDefault();
    if (this.menuButton) {
      this.menuButton.publishMenuClicked();
    }
  }

  showMenuButton(): boolean {
    return !this.menuButton ||
      (this.menuButton.requiresRole === undefined
        || this.loginInformationService.isHasRole(this.menuButton.requiresRole));
  }

}
