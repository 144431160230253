import {NgModule} from "@angular/core";
import {BoardViewComponent} from "./components/board-view/board-view.component";
import {RouterModule, Routes} from "@angular/router";
import {UserAuthenticatedGuard} from "../authentication/helpers/user-authenticated.guard";
import {ScheduleComponent} from "./components/schedule/schedule.component";
import {ScheduleListComponent} from "./components/schedule-list/schedule-list.component";
import {RegistrationDetailsComponent} from "./components/registration-details/registration-details.component";

const routes: Routes = [
  {path: 'board-view', component: BoardViewComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'new-schedule', component: ScheduleComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'schedule/:id', component: ScheduleComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'schedules', component: ScheduleListComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'registration-details/:id', component: RegistrationDetailsComponent, canActivate: [UserAuthenticatedGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes) ],
  exports: [RouterModule]
})
export class PlanningRoutingModule {
}
