<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col" style="width: 30%"
        class="table-header table-header-sortable col-one {{classSortingLable('grade')}}"
        (click)="handleSortingChange('grade')">
      <div class="sorting-header">
        <div class="sorting-lable">Stufe</div>
        <div class="sorting-toggle-buttons">
          <i-bs class="sorting" [name]="sortingDirectionButton('grade', 'asc')"></i-bs>
          <i-bs class="sorting" [name]="sortingDirectionButton('grade', 'desc')"></i-bs>
        </div>
      </div>
    </th>
    <th scope="col" style="width: max-content"
        class="table-header table-header-sortable {{classSortingLable('form')}}"
        (click)="handleSortingChange('form')">
      <div class="sorting-header">
        <div class="sorting-lable">Zug</div>
        <div class="sorting-toggle-buttons">
          <i-bs class="sorting" [name]="sortingDirectionButton('form', 'asc')"></i-bs>
          <i-bs class="sorting" [name]="sortingDirectionButton('form', 'desc')"></i-bs>
        </div>
      </div>
    </th>
  </tr>
  <tr>
    <th colspan="2" class="pageable-header">
      <div class="pageable-control-header">
        <div class="pageable-control"></div>
        <div class="pageable-lable">Seite {{classesList.page}} von {{classesList.maxPage}} ({{classesList.maxElements}})</div>
        <div class="pageable-control">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-dark pageable-button" id="page-size-button" ngbDropdownToggle>{{classesList.pageSize}}</button>
            <div ngbDropdownMenu aria-labelledby="page-size-button" class="pageable-drop-down">
              <div *ngFor="let pageSize of getPageSizeModel()">
                <button class="pageable-drop-down-content" ngbDropdownItem (click)="updatePageSize(pageSize)">{{pageSize}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="!classesList.isFirstPage()" class="pageable-active"
                name="skip-start-fill"
                ngbTooltip="Erste Seite" (click)="firstPage()"></i-bs>
          <i-bs *ngIf="classesList.isFirstPage()" class="pageable-deactive"
                name="skip-start-fill"
                ngbTooltip="Erste Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="classesList.hasPreviousPage()" class="pageable-active"
                name="caret-left-fill"
                ngbTooltip="Vorige Seite" (click)="previousPage()"></i-bs>
          <i-bs *ngIf="!classesList.hasPreviousPage()" class="pageable-deactive"
                name="caret-left-fill"
                ngbTooltip="Vorige Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="classesList.hasNextPage()" class="pageable-active"
                name="caret-right-fill"
                ngbTooltip="Nächste Seite" (click)="nextPage()"></i-bs>
          <i-bs *ngIf="!classesList.hasNextPage()" class="pageable-deactive"
                name="caret-right-fill"
                ngbTooltip="Nächste Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="!classesList.isLastPage()" class="pageable-active"
                name="skip-end-fill"
                ngbTooltip="Letzte Seite" (click)="lastPage()"></i-bs>
          <i-bs *ngIf="classesList.isLastPage()" class="pageable-deactive"
                name="skip-end-fill"
                ngbTooltip="Letzte Seite"></i-bs>
        </div>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let theCLass of classesList.list"
      class="clickable-row" (click)="onDetails(theCLass)">
    <td class="col-one">{{theCLass.grade}}</td>
    <td>{{theCLass.form}}</td>
  </tr>
  </tbody>
</table>
<div class="table-buttons">
  <button *ngIf="isAdmin()"
          class="btn btn-icon btn-icon-primary"
          placement="bottom-left" ngbTooltip="Neue Klasse erstellen"
          (click)="onAdd()">
    <i-bs name="plus-square-fill"></i-bs>
  </button>
</div>
