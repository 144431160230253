import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {ClassesListComponent} from "./classes-list/classes-list.component";
import {UserAuthenticatedGuard} from "../../../authentication/helpers/user-authenticated.guard";
import {ClassViewComponent} from "./class-view/class-view.component";
import {UserMemberGuard} from "../../../authentication/helpers/user-member.guard";
import {ClassStudentComponent} from "./class-student/class-student.component";

const routes: Routes = [
  {path: 'classes', component: ClassesListComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'class/:id', component: ClassViewComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'new-class', component: ClassViewComponent, canActivate: [UserAuthenticatedGuard]},
  {
    path: 'class/:classId/new-student',
    component: ClassStudentComponent,
    canActivate: [UserAuthenticatedGuard, UserMemberGuard]
  },
  {
    path: 'class/:classId/student/:id',
    component: ClassStudentComponent,
    canActivate: [UserAuthenticatedGuard]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class ClassesRoutingModule {
}
