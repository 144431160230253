import {MenuItem} from "./menu-item";
import {Navigationbar} from '../navigationbar/navigationbar';
import {Role} from "../../../authentication/models/role";
import {LogonState} from "../../../authentication/models/logon-state";

export class MenuItemLink implements MenuItem {
  readonly id: number;
  private navigationBar: Navigationbar;
  readonly requiresRole?: Role;
  readonly title: string;
  readonly route: string;
  readonly logonState?: LogonState;

  constructor(navigationBar: Navigationbar, id: number, title: string, route: string, logonState?: LogonState, requiresRole?: Role) {
    this.id = id;
    this.title = title;
    this.route = route;
    this.requiresRole = requiresRole;
    this.logonState = logonState;
    this.navigationBar = navigationBar;
  }

  getType(): string {
    return 'LINK';
  }

  public toString = () : string => {
    return `MenuItemLink (id: ${this.id}, title: ${this.title}, route: ${this.route})`;
  }

  publishMenuClicked() {
    this.navigationBar.publishMenuClicked();
  }
}
