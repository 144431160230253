import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Schedule} from "../../../institution/models/schedule";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";
import {ScheduleAdapterService} from "../../services/planning/schedule-adapter.service";

@Component({
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss']
})
export class RegistrationDetailsComponent implements OnInit {

  schedule: Schedule = this.createEmptySchedule();

  date: string | undefined;

  successMessage: string | undefined;
  errorMessage: string | undefined;

  private readonly DATE_FORMATTER: DateTimeFormatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');

  constructor(private route: ActivatedRoute,
              private router: Router,
              private scheduleAdapter: ScheduleAdapterService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  subscribeRouteParams(): void {
    this.route.params.subscribe(() => {
      this.loadSchedule()
        .then(() => console.log('Planung geladen.'))
        .catch(e => console.error('Fehler beim Laden einer Planung! ' + e));
    })
  }

  private loadSchedule(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let id = this.getScheduleIdFromRoute();
      if (id > 0) {
        this.scheduleAdapter.get(id).subscribe(result => {
          // TODO die Konvertierung sollte nicht von Hand passieren!
          this.schedule = Schedule.fixDateAttributes(result);
          this.date = this.schedule.date ? this.DATE_FORMATTER.format(this.schedule.date) : undefined;
          resolve();
        }, error => {
          reject(error);
        })
      } else {
        this.schedule = this.createEmptySchedule();
        resolve();
      }
    });
  }

  private getScheduleIdFromRoute() {
    // TODO warum gehe ich hier auf den Snapshot?
    let idParam = this.route.snapshot.paramMap.get('id');
    return idParam ? (parseInt(idParam) || 0) : 0;
  }

  private createEmptySchedule() {
    return new Schedule(undefined, LocalDate.now());
  }

  private clearMessages() {
    this.errorMessage = undefined;
    this.successMessage = undefined;
  }

  closeError() {
    this.errorMessage = undefined;
  }

  closeSuccess() {
    this.successMessage = undefined;
  }

  onGoToSchedule() {
    console.log('Schedule is ' + JSON.stringify(this.schedule));
    this.router.navigate(['/schedule', this.schedule.id])
      .catch(error => {
        this.errorMessage = error ? 'Bei der Navigation trat ein Fehler auf: ' + error : 'Bei der Navigation trat ein unbekannter Fehler auf.';
      });
  }
}
