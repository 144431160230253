import {LocalDate} from "@js-joda/core";
import {Class} from "./class";

export class Student {
  id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth?: LocalDate;
  theClass?: Class;
  classId?: number;

  constructor(id: number | undefined, firstName: string, lastName: string, dateOfBirth?: LocalDate, classId?: number) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.dateOfBirth = dateOfBirth;
    this.classId = classId;
  }

  setClass(theClass: Class) {
    this.theClass = theClass;
    this.classId = theClass.id;
  }

  static fixDateAttributes(s: Student): Student {
    // TODO die Konvertierung sollte nicht von Hand passieren!
    let d: LocalDate | undefined = (s.dateOfBirth && typeof s.dateOfBirth === 'string')
      ? LocalDate.parse(s.dateOfBirth) : s.dateOfBirth;
    return new Student(s.id, s.firstName, s.lastName, d, s.classId);
  }


}
