import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClassesListComponent} from './classes-list/classes-list.component';
import {NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {
  NgbAlert,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbInputDatepicker,
  NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import {ClassDetailComponent} from './class-detail/class-detail.component';
import {ClassesRoutingModule} from "./classes-routing.module";
import {ComponentsModule} from "../../../common/components/components.module";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {ClassViewComponent} from './class-view/class-view.component';
import {ClassStudentsListComponent} from './class-students-list/class-students-list.component';
import {ClassStudentComponent} from './class-student/class-student.component';
import {DateModule} from "../../../common/date/date.module";


@NgModule({
  declarations: [
    ClassesListComponent,
    ClassDetailComponent,
    ClassViewComponent,
    ClassStudentsListComponent,
    ClassStudentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxBootstrapIconsModule,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbTooltip,
    NgbDropdownToggle,
    ComponentsModule,
    NgbAlert,
    ClassesRoutingModule,
    DateModule,
    NgbInputDatepicker
  ]
})
export class ClassesModule {
}
