import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ScheduledCourse} from "../../models/core/scheduled-course";
import {ServerConfig} from "../../../config/server-config";

const apiUrl: string = '/api/scheduledCourse';

@Injectable({
  providedIn: 'root'
})
export class ScheduledCourseAdapterService {

  private baseUrl;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  getAll(): Observable<ScheduledCourse[]> {
    return this.http.get<ScheduledCourse[]>(`${this.baseUrl}/`);
  }

  get(id: any): Observable<ScheduledCourse> {
    return this.http.get<ScheduledCourse>(`${this.baseUrl}/${id}`);
  }

  update(scheduledCourse: ScheduledCourse): Observable<any> {
    return this.http.put(`${this.baseUrl}/${scheduledCourse.id}`, scheduledCourse);
  }
  updateAll(scheduledCourses: ScheduledCourse[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/updateAll`, scheduledCourses);
  }
  createAll(scheduledCourses: ScheduledCourse[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/createAll`, scheduledCourses);
  }

  findByScheduleId(scheduleId: number, selected?: boolean): Observable<ScheduledCourse[]> {
    let url = `${this.baseUrl}?scheduleId=${scheduleId}`;
    if (selected !== undefined) {
      url += `&selected=${selected}`;
    }
    return this.http.get<ScheduledCourse[]>(url);
  }

}
