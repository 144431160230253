import {ReplaySubject, Subject} from "rxjs";
import {Menu} from "../menu/menu";

export class Navigationbar {

  logo?: string;
  headlineLogo?: string;
  menuEntries: Menu[];

  clickEvent$: Subject<boolean> = new ReplaySubject<boolean>();

  constructor(logo?: string, headlineLogo?: string) {
    this.logo = logo;
    this.headlineLogo = headlineLogo;
    this.menuEntries = [];
  }

  public addMenu(menu: Menu) {
    this.menuEntries.push(menu);
  }

  public publishMenuClicked(): void {
    this.clickEvent$.next(true);
  }

  observeMenuClicked() {
    return this.clickEvent$;
  }

}
