import {Component, OnInit} from "@angular/core";
import {LogonState} from "../../models/logon-state";
import {LoginInformationService} from "../../services/login-information.service";
import {Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: any = {
    username: null,
    password: null
  };
  errorMessage: string = '';
  enhanced: boolean = false;

  loginForm = this.fb.group({
    username: ['', [
      Validators.required,
      Validators.minLength(5)]],
    password: ['', [
      Validators.required,
      Validators.minLength(8)]]
  });
  constructor(private fb: FormBuilder,
              private router: Router,
              private loginInformationService: LoginInformationService) { }

  ngOnInit(): void {
    if (this.loginInformationService.isAuthenticated()) {
      // Wenn der Benutzer schon angemeldet ist, dann Weiterleitung auf die Startseite
      this.router.navigate(['/']);
    }
  }

  get username(): string {
    let username = this.loginForm.get('username');
    return username && username.value ? username.value : '';
  }

  get password(): string {
    let password = this.loginForm.get('password');
    return password && password.value ? password.value : '';
  }

  onLogin(): void {
    this.clearMessages();
    if (!this.validate()) {
      this.errorMessage = 'Bitte geben Sie Benutzername und Passwort an!';
    } else {
      this.loginInformationService.login(this.username, this.password).then(
        logonState => {
          console.info('LoginComponent#login result ' + logonState);
          let url: string | undefined = this.loginInformationService.clearRedirectUrl();
          if (url) {
            this.router.navigateByUrl(url);
          } else {
            this.router.navigate(['/']);
          }
        },
        err => {
          console.error('login failed with ' + JSON.stringify(err));
          this.errorMessage = 'Anmeldung nicht erfolgreich. ' +
            'Bitte geben Sie einen gültigen Benutzernamen und ein gültiges Passwort ein!';
        }
      );
    }
  }

  public isLoggedIn(): boolean {
    return this.loginInformationService.isAuthenticated();
  }

  public isLoginFailed(): boolean {
    return this.loginInformationService.getCurrenLoginInformation().logonState === LogonState.invalid;
  }

  public roles(): string[] {
    let user = this.loginInformationService.getCurrenLoginInformation().user;
    return user ? user.roles : [];
  }

  private clearMessages() {
    this.errorMessage = '';
  }

  onResetPassword() {
    this.router.navigate(['/reset-password']);
  }

  onConfirmNewPassword() {
    this.router.navigate(['/confirm-new-password']);
  }

  onRegister() {
    this.router.navigate(['/register']);
  }

  closeErrors() {
    this.errorMessage = '';
  }

  private validate(): boolean {
    let username: string = this.username;
    let password: string = this.password;
    return username.length > 0 && password.length > 0;
  }

}
