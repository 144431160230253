<div class="editor">
  <!-- -->
  <div class="editor-row">
    <!-- Titel -->
    <div class="editor-header">
      Schüler
    </div>
  </div>
  <!-- -->
  <swg-input-field name="grade" lable="Vorname:"
                   [editing]="editing" type="text" placeholder="Vorname"
                   [(ngModel)]="student.firstName"></swg-input-field>
  <!-- -->
  <swg-input-field name="form" lable="Nachname:"
                   [editing]="editing" type="text" placeholder="Nachname"
                   [(ngModel)]="student.lastName"></swg-input-field>
  <!-- -->
  <div *ngIf="!editing" class="editor-row">
    <!-- date read-only -->
    <div class="editor-lable">
      <label for="input-date-ro">Geburtsdatum:</label>
    </div>
    <div id="input-date-ro" class="editor-input editor-readonly">{{student.dateOfBirth | localDate}}</div>
  </div>
  <div *ngIf="editing" class="editor-row">
    <!-- date -->
    <div class="editor-lable">
      <label for="input-date">Geburtsdatum:</label>
    </div>
    <div class="editor-input">
      <div class="editor-datepicker">
        <input id="input-date" placeholder="TT.MM.JJJJ"
               name="dp" [(ngModel)]="dateOfBirth" ngbDatepicker #d="ngbDatepicker"
               autocomplete="off"/>
        <div class="editor-datepicker-button">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>


  <div class="editor-row">
    <div *ngIf="editing">
      <button *ngIf="isAdmin()"
              id="button-save" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Speichern"
              (click)="onSave()">
        <i-bs name="file-earmark-check-fill"></i-bs>
      </button>
      <button id="button-cancel" class="editor-button btn btn-icon btn-icon-secondary"
              placement="bottom" ngbTooltip="Abbrechen"
              (click)="onCancel()">
        <i-bs name="x-square-fill"></i-bs>
      </button>
    </div>
    <div *ngIf="!editing">
      <button *ngIf="isAdmin()"
              id="button-edit" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Bearbeiten"
              (click)="editing = !editing">
        <i-bs name="pencil"></i-bs>
      </button>
      <button *ngIf="isAdmin()"
              id="button-delete" class="editor-button btn btn-icon btn-icon-danger"
              placement="bottom" ngbTooltip="Löschen"
              (click)="onDelete()">
        <i-bs name="trash-fill"></i-bs>
      </button>
      <button id="button-back" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Zurück zur Liste"
              (click)="onGoToList()">
        <i-bs name="caret-left-fill"></i-bs>
      </button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="editor-row">
    <ngb-alert [type]="'warning'" (closed)="closeError()">{{errorMessage}}</ngb-alert>
  </div>
  <div *ngIf="successMessage" class="editor-row">
    <ngb-alert [type]="'success'" (closed)="closeSuccess()">{{successMessage}}</ngb-alert>
  </div>
</div>
