import {Component, Input, OnInit} from '@angular/core';
import {Course} from "../../../models/course";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginInformationService} from "../../../../authentication/services/login-information.service";
import {CourseAdapterService} from "../../../services/course-adapter.service";

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  @Input() course: Course = this.createEmptyCourse();

  editing: boolean = false;

  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginInformationService: LoginInformationService,
              private coursesAdapter: CourseAdapterService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  private subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      let id: number = parseInt(params['id']) || 0;
      if (id !== 0) {
        this.loadCourse(id);
        this.editing = false;
      } else {
        this.course = this.createEmptyCourse();
        this.editing = true;
      }
    })
  }

  private createEmptyCourse(): Course {
    return new Course(undefined, '');
  }

  private loadCourse(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.coursesAdapter.get(id).subscribe(course => {
        this.course = course;
        resolve();
      }, error => {
        this.errorMessage = `Fehler beim Laden des Kurses ${id}: ${error}`;
        reject();
      })
    });
  }

  public onSave() {
    this.clearMessages();
    let isNew = this.isNew();
    this.coursesAdapter.createOrUpdate(this.course).subscribe(value => {
      console.log(JSON.stringify(value));
      // this.entity = value;
      // TODO das ist noch etwas gewagt - etwas mehr überprüfung könnte hier nicht schaden!
      this.course = Object.assign(this.createEmptyCourse(), value);
      this.editing = false;
      this.successMessage = `Kurs "${(this.course.name)}" wurde erfolgreich gespeichert.`;
      if (isNew) {
        this.router.navigate(['course', this.course.id]);
      }
    });
  }

  public onCancel() {
    if (typeof this.course.id !== 'number') {
      this.navigateToList();
    } else {
      this.editing = false;
      this.loadCourse(this.course.id);
    }
  }

  onDelete() {
    this.clearMessages();
    this.coursesAdapter.delete(this.course)
      .subscribe(result => {
        if (result === true) {
          this.navigateToList();
        } else {
          this.errorMessage = `Fehler beim Löschen des Kurses "${this.course.name}".`;
        }
      })
  }

  onGoToList() {
    this.navigateToList();
  }

  private navigateToList() {
    this.router.navigate(['/courses']);
  }

  isNew(): boolean {
    return typeof this.course.id !== 'number';
  }

  isAdmin(): boolean {
    return this.loginInformationService.isAdmin();
  }

  private clearMessages() {
    this.successMessage = undefined;
    this.errorMessage = undefined;
  }

  closeError() {
    this.errorMessage = undefined;
  }

  closeSuccess() {
    this.successMessage = undefined;
  }

}
