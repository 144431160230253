<div class="editor">
  <!-- -->
  <div class="editor-row">
    <!-- Titel -->
    <div class="editor-header">
      Klasse
    </div>
  </div>
  <!-- -->
  <swg-input-field name="grade" lable="Stufe:"
                   [editing]="editing" type="text" placeholder="Klassenstufe"
                   [(ngModel)]="entity.grade"></swg-input-field>
  <!-- -->
  <swg-input-field name="form" lable="Zug:"
                   [editing]="editing" type="text" placeholder="Klassenzug"
                   [(ngModel)]="entity.form"></swg-input-field>
  <div class="editor-row">
    <div *ngIf="editing">
      <button *ngIf="isAdmin()"
              id="button-save" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Speichern"
              (click)="onSave()">
        <i-bs name="file-earmark-check-fill"></i-bs>
      </button>
      <button id="button-cancel" class="editor-button btn btn-icon btn-icon-secondary"
              placement="bottom" ngbTooltip="Abbrechen"
              (click)="onCancel()">
        <i-bs name="x-square-fill"></i-bs>
      </button>
    </div>
    <div *ngIf="!editing">
      <button *ngIf="isAdmin()"
              id="button-edit" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Bearbeiten"
              (click)="editing = !editing">
        <i-bs name="pencil"></i-bs>
      </button>
      <button *ngIf="isAdmin()"
              id="button-delete" class="editor-button btn btn-icon btn-icon-danger"
              placement="bottom" ngbTooltip="Löschen"
              (click)="onDelete()">
        <i-bs name="trash-fill"></i-bs>
      </button>
      <button id="button-back" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Zurück zur Liste"
              (click)="onGoToList()">
        <i-bs name="caret-left-fill"></i-bs>
      </button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="editor-row">
    <ngb-alert [type]="'warning'" (closed)="closeError()">{{errorMessage}}</ngb-alert>
  </div>
  <div *ngIf="successMessage" class="editor-row">
    <ngb-alert [type]="'success'" (closed)="closeSuccess()">{{successMessage}}</ngb-alert>
  </div>
</div>
