import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PageableList} from "../../../util/paging/pageable-list";
import {SortDirection, Sorting} from "../../../util/sorting/sorting";
import {ActivatedRoute} from "@angular/router";
import {IconNamesEnum} from "ngx-bootstrap-icons";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {ExScheduledStudent} from "../../models/expedience/ex-scheduled-student";
import {ExpedienceStudentService} from "../../services/expedience/ex-scheduled-student-service";

@Component({
  selector: 'app-schedule-student-list',
  templateUrl: './schedule-student-list.component.html',
  styleUrls: ['./schedule-student-list.component.scss']
})
export class ScheduleStudentListComponent implements OnInit, OnChanges {

  @Input()
  editing: boolean = false;

  allSelected: boolean = false;

  scheduleId: number = 0;

  scheduledStudents: PageableList<ExScheduledStudent> = new PageableList<ExScheduledStudent>([]);

  sortKey: string = 'lastName';
  direction: SortDirection = SortDirection.asc;

  constructor(private route: ActivatedRoute,
              private expedienceStudentService: ExpedienceStudentService,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.info(JSON.stringify(changes));
    let simpleChange = changes['editing'];
    if (!simpleChange.firstChange && !simpleChange.currentValue && simpleChange.previousValue) {
      console.log('Editieren beendet, lade die Daten neu für Seite ' + this.scheduledStudents.page);
      this.loadScheduledStudents(this.scheduledStudents.page);
    }
  }

  subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      let id: number = this.getScheduleIdFromString(params['id']);
      if (id > 0) {
        this.scheduleId = id;
        this.loadScheduledStudents(1);
      } else {
        this.allSelected = false;
        this.scheduledStudents = new PageableList<ExScheduledStudent>([]);
      }
    })
  }

  private getScheduleIdFromString(idParam: string | null) {
    return idParam ? (parseInt(idParam) || 0) : 0;
  }

  private loadScheduledStudents(page: number) {
    console.log('loadScheduledStudents for schedule ' + this.scheduleId);
    this.expedienceStudentService.findByScheduleIdPaged(
      this.scheduleId, page, this.scheduledStudents.pageSize, this.sortKey, this.direction).then(
      scheduledStudents => {
        this.updateAllSelected(scheduledStudents.list);
        this.scheduledStudents = PageableList.from(scheduledStudents, scheduledStudents.list);
      }
    );
  }

  private updateAllSelected(scheduledStudents: ExScheduledStudent[]) {
    this.allSelected = scheduledStudents
      .map(s => s.selected)
      .reduce((a, b) => a && b);
  }

  classSortingLable(columnName: string): string {
    return Sorting.getClassSortingLable(columnName, this.sortKey);
  }

  sortingDirectionButton(columnName: string, directionString: string): IconNamesEnum {
    return Sorting.getSortingDirectionButtonName(directionString, columnName, this.sortKey, this.direction);
  }

  handleSortingChange(columnName: string) {
    if (columnName === this.sortKey) {
      this.direction = this.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      this.sortKey = columnName;
      this.direction = columnName === 'date' ? SortDirection.desc : SortDirection.asc;
    }
    this.loadScheduledStudents(this.scheduledStudents.page);
  }

  firstPage() {
    this.loadScheduledStudents(1);
  }

  previousPage() {
    this.loadScheduledStudents(this.scheduledStudents.page - 1);
  }

  nextPage() {
    this.loadScheduledStudents(this.scheduledStudents.page + 1);
  }

  lastPage() {
    this.loadScheduledStudents(this.scheduledStudents.maxPage);
  }

  getPageSizeModel(): number[] {
    return [5, 10, 25];
  }

  updatePageSize(pageSize: number) {
    this.scheduledStudents.pageSize = pageSize;
    this.loadScheduledStudents(1)
  }

  isMember(): boolean {
    return this.loginInformationService.isAdmin();
  }

  changeStudentSelection() {
    this.updateAllSelected(this.scheduledStudents.list);
  }

  changeAllSelected() {
    this.scheduledStudents.list.forEach(s => s.selected = this.allSelected);
  }

}
