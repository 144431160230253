import {Component, Input, OnInit} from '@angular/core';
import {Student} from "../../../models/student";
import {ActivatedRoute, Router} from "@angular/router";
import {Class} from "../../../models/class";
import {ClassAdapterService} from "../../../services/class-adapter.service";
import {StudentAdapterService} from "../../../services/student-adapter.service";
import {LoginInformationService} from "../../../../authentication/services/login-information.service";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";
import {NgbDateAdapter, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {CustomDateAdapter} from "../../../../common/adapter/ngb/custom-date-adapter";
import {LocalDatePipe} from "../../../../common/date/local-date-pipe";
import {CustomDateParserFormatter} from "../../../../common/adapter/ngb/custom-date-parser-formatter";

@Component({
  selector: 'app-class-student',
  templateUrl: './class-student.component.html',
  styleUrls: ['./class-student.component.scss'],
  providers: [
    LocalDatePipe,
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class ClassStudentComponent implements OnInit {

  private readonly DATE_FORMATTER: DateTimeFormatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');

  @Input() student: Student = this.createEmptyStudent();

  private theClass: Class = new Class(undefined,'','');

  editing: boolean = false;
  dateOfBirth: string | undefined;

  successMessage: string | undefined;
  errorMessage: string | undefined;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private classesAdapter: ClassAdapterService,
              private studentAdapter: StudentAdapterService,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit(): void {
    this.initializeClassAndStudent();
  }

  private initializeClassAndStudent() {
    this.loadClass().then(
      theClass => {
        this.theClass = theClass;
        this.loadStudent()
          .then(() => console.log('Schüler geladen.'))
          .catch(e => console.error('Fehler beim Laden eines Schülers! ' + e));
      }
    )
  }

  private async loadClass(): Promise<Class> {
    return new Promise<Class>((resolve, reject) => {
      let classId = this.getClassIdFromRoute();
      if (classId > 0) {
        this.classesAdapter.get(classId).subscribe(theClass => {
          resolve(theClass);
        }, error => {
          reject(`Klasse mit ID ${classId} konnte nicht geladen werden: ${error}!`);
        });
      } else {
        reject('Keine gültige ID übergeben!');
      }
    })
  }

  private loadStudent(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let idParam = this.route.snapshot.paramMap.get('id');
      let id: number = idParam ? (parseInt(idParam) || 0) : 0;
      if (id > 0) {
        this.studentAdapter.get(id).subscribe(result => {
          // TODO die Konvertierung sollte nicht von Hand passieren!
          this.student = this.getStudentFromResult(result);
          this.dateOfBirth = this.student.dateOfBirth ? this.DATE_FORMATTER.format(this.student.dateOfBirth) : undefined;
          this.editing = false;
          resolve();
        }, error => {
          reject(error);
        })
      } else {
        this.student = this.createEmptyStudent();
        this.editing = true;
        resolve();
      }
    });
  }

  onSave() {
    this.clearMessages();
    let isNew = this.isNew();
    this.student.setClass(this.theClass);
    this.student.dateOfBirth =
      this.dateOfBirth ? LocalDate.parse(this.dateOfBirth, this.DATE_FORMATTER) : undefined;
    this.studentAdapter.createOrUpdate(this.student).subscribe(result => {
      this.student = this.getStudentFromResult(result);
      this.editing = false;
      this.successMessage = 'Schüler wurde erfolgreich gespeichert.';
      if (isNew) {
        let classIdFromRoute = this.getClassIdFromRoute();
        this.router.navigate(['/class/' + classIdFromRoute + '/student', this.student.id]);
      }
    }, error => {
      this.errorMessage = `Fehler beim Speichern des Schülers: ${JSON.stringify(error)}`;
      console.log(JSON.stringify(error));
    })
  }

  onCancel() {
    if (this.isNew()) {
      this.navigateToList();
    } else {
      this.editing = false;
      this.loadStudent()
        .then(() => console.log('Schüler geladen.'))
        .catch(e => console.error('Fehler beim Laden eines Schülers! ' + e));
    }
  }

  onDelete() {
    this.clearMessages();
    this.studentAdapter.delete(this.student).subscribe(
      result => {
        if (result === true) {
          this.navigateToList();
        } else {
          this.errorMessage = 'Schüler konnte nicht gelöscht werden!';
        }
      }
    );
  }

  onGoToList() {
    this.navigateToList();
  }

  private clearMessages() {
    this.errorMessage = undefined;
    this.successMessage = undefined;
  }

  closeError() {
    this.errorMessage = undefined;
  }

  closeSuccess() {
    this.successMessage = undefined;
  }

  private navigateToList() {
    let classId: number = this.getClassIdFromRoute();
    this.router.navigate(['/class', classId]);
  }

  private isNew(): boolean {
    return typeof this.student.id !== 'number';
  }


  isAdmin(): boolean {
    return this.loginInformationService.isAdmin();
  }

  private getClassIdFromRoute() {
    let classIdParam = this.route.snapshot.paramMap.get('classId');
    return classIdParam ? (parseInt(classIdParam) || 0) : 0;
  }


  // noinspection JSMethodCanBeStatic
  private createEmptyStudent() {
    return new Student(undefined, '', '');
  }

  private getStudentFromResult(student: Student) {
    let d: LocalDate | undefined = (student.dateOfBirth && typeof student.dateOfBirth === 'string')
      ? LocalDate.parse(student.dateOfBirth) : student.dateOfBirth;
    let myStudent = new Student(student.id, student.firstName, student.lastName, d, student.classId);
    return myStudent;
  }

}
