import {Injectable} from '@angular/core';
import {ExScheduledCourse} from "../../models/expedience/ex-scheduled-course";
import {catchError, combineLatestAll, forkJoin, from, map, of} from "rxjs";
import {ScheduledCourseAdapterService} from "../core/scheduled-course-adapter.service";
import {CourseAdapterService} from "../../../institution/services/course-adapter.service";
import {ScheduledCourse} from "../../models/core/scheduled-course";
import {SortDirection} from "../../../util/sorting/sorting";
import {PageableList} from "../../../util/paging/pageable-list";
import {ScheduledCoursePageableAdapterService} from "../core/scheduled-course-pageable-adapter.service";

@Injectable({
  providedIn: 'root'
})
export class ExpedienceCourseService {

  constructor(private scheduledCourseAdapter: ScheduledCourseAdapterService,
              private scheduledCoursePageableAdapter: ScheduledCoursePageableAdapterService,
              private courseAdapter: CourseAdapterService) {
  }

  findByScheduleId(scheduleId: number, selected: boolean): Promise<ExScheduledCourse[]> {
    return new Promise<ExScheduledCourse[]>((resolve) => {
      this.scheduledCourseAdapter.findByScheduleId(scheduleId, selected).subscribe({
        next: data => {
          if (data.length === 0) {
            resolve([]);
          } else {
            from(data).pipe(
              map(c => this.enrichExScheduledCourse(ScheduledCourse.map(c))),
              combineLatestAll()
            ).subscribe({
              next: courses => {
                resolve(courses);
              }
            });
          }
        }
      })
    });
  }

  findByScheduleIdPaged(scheduleId: number, page?: number, pageSize?: number, sortKey?: string,
                        direction?: SortDirection): Promise<PageableList<ExScheduledCourse>> {
    return new Promise<PageableList<ExScheduledCourse>>((resolve) => {
      this.scheduledCoursePageableAdapter.findByScheduleIdPaged(scheduleId, page, pageSize, sortKey, direction)
        .subscribe({
          next: data => {
            from(data.list).pipe(
              map(c => this.enrichExScheduledCourse(ScheduledCourse.map(c))),
              combineLatestAll()
            ).subscribe({
              next: courses => {
                resolve(new PageableList<ExScheduledCourse>(courses, data.page, data.pageSize, data.maxElements));
              }
            });
          }
        });
    });
  }

  private enrichExScheduledCourse(scheduledCourse: ScheduledCourse): Promise<ExScheduledCourse> {
    return new Promise<ExScheduledCourse>((resolve) => {
      // TODO Fehlerbehandlung https://www.learnrxjs.io/learn-rxjs/operators/combination/forkjoin
      forkJoin({
        course: this.courseAdapter.get(scheduledCourse.courseId),
        scheduledCourse:
          scheduledCourse.courseId !== undefined && scheduledCourse.courseId !== null
            ? this.scheduledCourseAdapter.get(scheduledCourse.courseId).pipe(catchError(() => of(undefined)))
            : of(undefined)
      }).subscribe({
          next: data => {
            let selected = scheduledCourse.selected === true;
            let course = new ExScheduledCourse(
              scheduledCourse.id, scheduledCourse.scheduleId, scheduledCourse.courseId,
              selected, scheduledCourse.drawX, scheduledCourse.drawY, data.course);
            resolve(course);
          }
        }
      );
    });
  }

}
