import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-class-view',
  templateUrl: './class-view.component.html',
  styleUrls: ['./class-view.component.scss']
})
export class ClassViewComponent implements OnInit {

  editing: boolean = true;
  isNew: boolean = true;
  showStudents: boolean = true;

  constructor(private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  private subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.editing = false;
        this.isNew = false;
      } else {
        this.editing = true;
        this.isNew = true;
      }
    })
  }

  public onCancel() {
    if (this.isNew) {
      this.navigateToList();
    } else {
      this.editing = false;
    }
  }

  private navigateToList() {
    this.router.navigate(['/classes']);
  }


}
