import {Component, DoCheck, OnInit, ViewChild} from '@angular/core';
import {config, Observable, of} from "rxjs";
import {KonvaComponent, StageComponent} from "ng2-konva";
import Konva from "konva";
import {Position} from "../../types/position";
import {ExpedienceStudentService} from "../../services/expedience/ex-scheduled-student-service";
import {ExScheduledStudent} from "../../models/expedience/ex-scheduled-student";
import {PlanningAdapterService} from "../../services/planning/planning-adapter.service";
import {LocalDateFormatter} from "../../../common/date/local-date-formatter";
import {LocalDate, LocalDateTime} from "@js-joda/core";
import {ScheduleAdapterService} from "../../services/planning/schedule-adapter.service";
import {Schedule} from "../../../institution/models/schedule";
import {ExpedienceCourseService} from "../../services/expedience/ex-scheduled-course-service";
import {ExScheduledCourse} from "../../models/expedience/ex-scheduled-course";
import Group = Konva.Group;

@Component({
  selector: 'app-board-view',
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.scss']
})
export class BoardViewComponent implements DoCheck, OnInit {

  @ViewChild('stage') stage!: StageComponent;
  @ViewChild('layer') layer!: KonvaComponent;

  private selectedSchedule: Schedule | undefined;
  private availableDates: string[] = [];

  private position: Position | undefined;

  private checkingForUpdates: boolean = false;
  private elementMoving: boolean = false;
  private hasChangedLocally: boolean = false;
  scale: number = 100;
  boardStyle = {width: '1200px', height: '900px'};

  private readonly _studentWidth = 100;
  private readonly _studentHeight = 50;
  private readonly _studentHDist = 20;
  private readonly _studentVDist = 20;

  private readonly _studentToCoursesPadding = 200;

  private readonly _courseWidth = 400;
  private readonly _courseHeight = 300;
  private readonly _courseHDist = 20;
  private readonly _courseVDist = 20;


  private lastUpdateTime: LocalDateTime | undefined;
  private drawingElements: Group[] = [];

  private drawableCourseInfos: DrawableCourseInfo[] = [];

  constructor(private expedienceCourseService: ExpedienceCourseService,
              private expedienceStudentService: ExpedienceStudentService,
              private planningAdapter: PlanningAdapterService,
              private scheduleAdapter: ScheduleAdapterService) {
  }

  ngOnInit() {
    this.updateAvailableDates(true);
  }

  private updateAvailableDates(updateSelectedSchedule: boolean = false) {
    this.scheduleAdapter.findAll().subscribe(schedules => {
      const today = LocalDate.now();
      this.availableDates = [];
      for (const s of schedules) {
        let schedule: Schedule = Schedule.fixDateAttributes(s);
        let date = LocalDateFormatter.format(schedule.date);
        console.log('Datum ' + date + ' gefunden.');
        if (date) {
          this.availableDates.push(date);
        }
        if (updateSelectedSchedule) {
          if (!this.selectedSchedule) {
            this.selectedSchedule = schedule;
          } else {
            if (today.isBefore(schedule.date) && this.selectedSchedule.date.isAfter(schedule.date)) {
              this.selectedSchedule = schedule;
            }
          }
        }
      }
    });
  }

  private async drawPlanningPeriod() {
    let classesAndStudentsMap = await this.createClassesAndStudentsMap();
    // Calculating maxStudentColumnCount for the students
    // TODO Die Anzahl der Zeilen kann kleiner sein, wenn einzelne Klassen wesentlich weniger Schüler:innen haben
    let maxStudentColumnCount: number = this.calculateMaxStudentColumnCount(classesAndStudentsMap);
    let maxStudentRowCount: number = this.calculateMaxStudentRowCount(classesAndStudentsMap, maxStudentColumnCount);
    console.log(`students: ${maxStudentColumnCount} x ${maxStudentRowCount}`);

    // Calculate maxCourseColumnCount and maxCourseRowCount for the courses
    let scheduleId: number = this.selectedSchedule ? (this.selectedSchedule.id ? this.selectedSchedule.id : 0) : 0;
    let courses: ExScheduledCourse[] = scheduleId > 0
      ? await this.expedienceCourseService.findByScheduleId(scheduleId, true)
      : [];
    let maxCourseColumnCount: number = courses.length > 0 ? Math.ceil(Math.sqrt(courses.length)) : 0;
    let maxCourseRowCount: number = courses.length > 0 ? Math.ceil(courses.length / maxCourseColumnCount) : 0;
    console.log(`courses: ${maxCourseColumnCount} x ${maxCourseRowCount}`);

    let studentWidth: number =
      maxStudentColumnCount * (this._studentWidth + this._studentVDist) + this._studentVDist;
    let studentHeight: number =
      maxStudentRowCount * (this._studentHeight + this._studentHDist) + this._studentHDist;

    let courseWidth: number = maxCourseColumnCount * (this._courseWidth + this._courseVDist);
    let courseHeight: number = maxCourseRowCount * (this._courseHeight + this._courseHDist) + this._courseHDist;

    console.log(`studentWidth: ${studentWidth}, studentHeight: ${studentHeight}`);
    console.log(`courseWidth: ${courseWidth}, courseHeight: ${courseHeight}`);
    let courseFieldOffset: number = this._studentVDist + studentWidth + this._studentToCoursesPadding + this._courseVDist;
    console.log(`courseFieldOffset: ${courseFieldOffset}`);
    let width: number = courseFieldOffset + courseWidth;
    let height: number = Math.max(studentHeight, courseHeight);
    console.log('width: ' + width + ', height = ' + height);
    this.resetStageSize(width, height);
    // TODO aktuell ist hier die Reihenfolge der Aufrufe wichtig, damit die Kurse hinter(!) den Schülern liegen
    this.drawPlannedCourses(courses, maxCourseColumnCount, courseFieldOffset);
    this.drawStudents(classesAndStudentsMap, maxStudentColumnCount);
  }

  private async createClassesAndStudentsMap() {
    let students: ExScheduledStudent[] = this.selectedSchedule && this.selectedSchedule.id ?
      await this.expedienceStudentService.findByScheduleId(this.selectedSchedule.id) : [];
    let classesAndStudentsMap: Map<string, ExScheduledStudent[]> = new Map<string, ExScheduledStudent[]>;
    for (const student of students) {
      let theClass = student.student?.theClass;
      let key: string = 'unknown';
      if (theClass) {
        key = theClass.grade + theClass.form;
      }
      let list = classesAndStudentsMap.get(key);
      if (!list) {
        classesAndStudentsMap.set(key, [student]);
      } else {
        list.push(student);
      }
    }
    return classesAndStudentsMap;
  }

  private calculateMaxStudentColumnCount(classesAndStudentsMap: Map<string, ExScheduledStudent[]>): number {
    let maxStudentColumnCount: number = 0;
    for (const classKey of classesAndStudentsMap.keys()) {
      let list: ExScheduledStudent[] | undefined = classesAndStudentsMap.get(classKey);
      let studentList: ExScheduledStudent[] = list ? list : [];
      let columnCount: number = Math.ceil(Math.sqrt(studentList.length));
      maxStudentColumnCount = Math.max(columnCount, maxStudentColumnCount);
    }
    return maxStudentColumnCount;
  }

  private calculateMaxStudentRowCount(classesAndStudentsMap: Map<string, ExScheduledStudent[]>, maxStudentColumnCount: number): number {
    let maxStudentRowCount: number = 0;
    for (const classKey of classesAndStudentsMap.keys()) {
      let list: ExScheduledStudent[] | undefined = classesAndStudentsMap.get(classKey);
      let studentList: ExScheduledStudent[] = list ? list : [];
      let rowCount: number = Math.ceil(studentList.length / maxStudentColumnCount);
      maxStudentRowCount += rowCount;
    }
    return maxStudentRowCount;
  }

  private resetStageSize(width: number, height: number) {
    let theScale = this.scale / 100;
    this.stage.getStage().width(Math.round(width * theScale));
    this.stage.getStage().height(Math.round(height * theScale));
    this.stage.getStage().scaleX(theScale);
    this.stage.getStage().scaleY(theScale);
    this.boardStyle = {width: (theScale * width) + 'px', height: (theScale * height) + 'px'};
  }

  private async redrawPlanningPeriod() {
    // TODO hier kann noch optimiert werden, dass nicht alle Objekte entfernt und neu
    //  gezeichnet werden.
    while (this.drawingElements.length > 0) {
      this.drawingElements.pop()?.destroy();
    }
    await this.drawPlanningPeriod();
    await this.sleep(250);
  }

  private drawStudents(classesAndStudentMap: Map<string, ExScheduledStudent[]>, studentFieldSize: number) {
    let height = this._studentHeight;
    let width = this._studentWidth;
    let hDist = this._studentHDist;
    let vDist = this._studentVDist;

    // Zeilen gelten "global" für alle Klassen
    let studentRow = 0;

    console.log(this.stage.getStage().getWidth() + ' x ' + this.stage.getStage().getHeight());

    let scale = this.scale / 100;

    let maxX: number = Math.round(this.stage.getStage().getWidth() / scale) - width;
    let maxY: number = Math.round(this.stage.getStage().getHeight() / scale )- height;

    console.log(maxX + ' x ' + maxY);

    for (const classKey of classesAndStudentMap.keys()) {
      let studentColumn = 0;
      let students = classesAndStudentMap.get(classKey);
      if (students) {
        for (const student of students) {
          if (studentColumn >= studentFieldSize) {
            studentColumn = 0;
            studentRow++;
          }
          if (student.getPosition() === undefined) {
            student.setPosition({
              x: vDist + studentColumn * (width + vDist),
              y: hDist + studentRow * (height + hDist),
            });
          }
          let x: number = Math.min(student.drawX ? student.drawX : vDist, maxX);
          let y: number = Math.min(student.drawY ? student.drawY : hDist, maxY);
          const studentGroup: Group = new Konva.Group({
            x: x,
            y: y,
            draggable: true
          });
          const studentRect = new Konva.Rect({
            x: 0,
            y: 0,
            width: width,
            height: height,
            fill: '#de596e',
            stroke: '#a1152c',
            draggable: false,
            strokeWidth: 2,
          });
          const studentName = new Konva.Text({
            x: 5,
            y: 5,
            stroke: '#0f0f0f',
            strokeWidth: 1,
            text: student.getName()
          });
          const studentGrade = new Konva.Text({
            x: 5,
            y: 20,
            stroke: '#0f0f0f',
            strokeWidth: 0,
            text: 'Klasse ' + classKey
          });
          studentGroup.add(studentRect, studentName, studentGrade);

          // add cursor styling
          studentGroup.on('mouseover', function () {
            document.body.style.cursor = 'pointer';
          });
          studentGroup.on('mouseout', function () {
            document.body.style.cursor = 'default';
          });

          // Events für Drag & Drop
          studentGroup.addEventListener('pointerdown dragstart tap',
            () => this.handleStudentPicked());
          studentGroup.addEventListener('pointerup dragend',
            () => this.handleStudentReleased(student));

          // console.log(studentCount + ': ' + student.getName() + ' ' + JSON.stringify(studentRect));
          studentColumn++;
          this.layer.getStage().add(studentGroup);
          this.drawingElements.push(studentGroup);
        }
      }
      studentRow++;
    }
    this.layer.getStage().draw();
  }

  private drawPlannedCourses(courses: ExScheduledCourse[], courseFieldSize: number, courseFieldOffset: number) {
    let courseRow = 0;
    let courseColumn = 0;
    let height = this._courseHeight;
    let width = this._courseWidth;
    let hDist = this._courseHDist;
    let vDist = this._courseVDist;
    this.drawableCourseInfos = [];
    for (const course of courses) {
      if (courseColumn >= courseFieldSize) {
        courseColumn = 0;
        courseRow++;
      }
      // Put the info about the course an its position in the array
      this.drawableCourseInfos.push(new DrawableCourseInfo(
        course,
        new Rectangle(courseFieldOffset + courseColumn * (width + vDist),
          hDist + courseRow * (height + hDist),
          width,
          height)));
      const courseGroup: Group = new Konva.Group({
        x: courseFieldOffset + courseColumn * (width + vDist),
        y: hDist + courseRow * (height + hDist),
      });
      const courseName = new Konva.Text({
        x: 5,
        y: 5,
        stroke: '#0f0f0f',
        strokeWidth: 1,
        text: course.course?.name
      });
      const courseInfo = new Konva.Text({
        x: 5,
        y: 20,
        stroke: '#0f0f0f',
        strokeWidth: 0,
        // TODO: Hier muss noch mehr Information dargestellt werden
        text: course.course?.description
      });
      const courseRect = new Konva.Rect({
        x: 0,
        y: 0,
        width: width,
        height: height,
        fill: '#fae6a6',
        stroke: '#ffc202',
        draggable: false,
        strokeWidth: 2,
      });
      courseGroup.add(courseRect, courseName, courseInfo);
      courseColumn++;
      this.layer.getStage().add(courseGroup);
      this.drawingElements.push(courseGroup);
    }
    this.layer.getStage().draw();
  }

  ngDoCheck() {
    // this.updateAvailableDates();
    if (!this.elementMoving && !this.checkingForUpdates) {
      this.checkingForUpdates = true;
      this.checkForUpdates().then(
        needsRedraw => {
          if (needsRedraw) {
            this.redrawPlanningPeriod().then(() => {
              this.sleep(150).then(() => {
                this.checkingForUpdates = false;
              })
            });
          } else {
            this.sleep(100).then(() => {
              this.checkingForUpdates = false;
            })
          }
        }
      )
    }
  }

  private sleep(ms: number): Promise<void> {
    return new Promise<void>(r => setTimeout(r, ms));
  }

  private checkForUpdates(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (!this.selectedSchedule || !this.selectedSchedule.id) {
        // noch nichts zum Zeichnen da ...
        resolve(false);
      } else if (this.hasChangedLocally) {
        this.hasChangedLocally = false;
        resolve(true);
      } else {
        this.planningAdapter.getLastSignificantChangeForSchedule(this.selectedSchedule.id).subscribe(timeString => {
          let dateTime = LocalDateFormatter.toLocalDateTime(timeString);
          let hasChanged: boolean = !dateTime || !this.lastUpdateTime || dateTime.isAfter(this.lastUpdateTime);
          this.lastUpdateTime = dateTime;
          resolve(hasChanged);
        });
      }
    });
  }

  public configStage: Observable<any> = of({
    width: 1200,
    height: 900
  });

  private handleStudentPicked() {
    if (this.elementMoving) {
      return;
    }
    this.elementMoving = true;
    let position = this.stage.getStage().getPointerPosition();
    if (position) {
      let scale = this.scale / 100;
      this.position = {
        x: Math.round(position.x / scale),
        y: Math.round(position.y / scale)
      }
      console.log('position = ' + JSON.stringify(this.position));
    }
  }

  private handleStudentReleased(student: ExScheduledStudent) {
    if (!this.elementMoving) {
      return;
    }
    let newPosition = this.stage.getStage().getPointerPosition();
    console.log('new position = ' + JSON.stringify(newPosition));
    let studentPosition = student.getPosition();
    if (studentPosition === undefined) {
      studentPosition = {x: 0, y: 0};
    }
    if (this.position) {
      let scale = this.scale / 100;
      let newScaledPosition = {
        x: Math.round(newPosition.x / scale),
        y: Math.round(newPosition.y / scale)
      }
      console.log('newScaledPosition = ' + JSON.stringify(newScaledPosition));
      student.setPosition({
        x: studentPosition.x + newScaledPosition.x - this.position.x,
        y: studentPosition.y + newScaledPosition.y - this.position.y
      });
      this.position = undefined;
      console.log('updatedStudentPosition = ' + JSON.stringify(student.getPosition()));
    }
    // console.log('student = ' + JSON.stringify(student));
    if (student.hasPosition()) {
      let course: ExScheduledCourse | undefined = undefined;
      let overlap: number = 0;
      let rectangle = new Rectangle(
        this.asNumber(student.drawX), this.asNumber(student.drawY), this._studentWidth, this._studentHeight);
      for (const drawableCourseInfo of this.drawableCourseInfos) {
        let rOverlap: number = rectangle.overlap(drawableCourseInfo.rectangle);
        if (rOverlap > overlap) {
          course = drawableCourseInfo.course;
          overlap = rOverlap;
        }
      }
      if (course && course.id) {
        student.scheduledCourseId = course.id;
      } else {
        student.unsetScheduledCourseId();
      }
      console.log('student changed position!');
      this.expedienceStudentService.updateStudent(student).then(
        () => {
          console.log('... stop moving element at position ' + JSON.stringify(student.getPosition()));
          this.elementMoving = false;
          // TODO hier musst du nach geänderten Zeiten suchen!
        }
      );
    }
    console.log(JSON.stringify(student));
  }

  asNumber(n : number | undefined) {
    return n ? n : 0;
  }

  protected readonly config = config;

  getSelectedDate(): string {
    const date = this.selectedSchedule ? LocalDateFormatter.format(this.selectedSchedule.date) : undefined;
    return date ? date : '(leer)';
  }

  getAvailablePlannedDates(): string[] {
    return this.availableDates;
  }

  updatePlannedDate(date: string) {
    console.log('Aktualisiere das Datum auf ' + date);
    let localDate = LocalDateFormatter.toLocalDate(date);
    console.log('LocalDate ist ' + localDate);
    if (localDate) {
      this.scheduleAdapter.findByDate(localDate).subscribe(schedules => {
        console.log('Habe ' + schedules.length + ' geplante Tage am ' + date + ' gefunden.');
        if (schedules && schedules.length > 0) {
          let newSchedule = Schedule.fixDateAttributes(schedules[0]);
          if (!this.selectedSchedule || (this.selectedSchedule.id !== newSchedule.id)) {
            console.log('Setze den gewählten geplanten Tag neu.');
            this.selectedSchedule = newSchedule;
            this.hasChangedLocally = true;
          }
        }
      })
    }
  }

  scaleUp() {
    this.scale = this.scale + 10;
    this.hasChangedLocally = true;
  }

  scaleDown() {
    this.scale = this.scale - 10;
    this.hasChangedLocally = true;
  }

  scale100() {
    this.scale = 100;
    this.hasChangedLocally = true;
  }

  isNotScaleUpPossible(): boolean {
    return this.scale >= 400;
  }

  isNotScaleDownPossible(): boolean {
    return this.scale <= 10;
  }

}

class DrawableCourseInfo {

  private _course: ExScheduledCourse;

  private _rectangle: Rectangle;

  constructor(course: ExScheduledCourse, position: Rectangle) {
    this._course = course;
    this._rectangle = position;
  }

  get course(): ExScheduledCourse {
    return this._course;
  }

  get rectangle(): Rectangle {
    return this._rectangle;
  }
}

type Point = {
  x: number,
  y: number
};

class Rectangle {
  private p1: Point;
  private p2: Point;

  constructor(x: number, y: number, width: number, height: number) {
    this.p1 = {
      x: x,
      y: y
    };
    this.p2 = {
      x: x + width,
      y: y + height
    }
  }

  overlap(rect: Rectangle): number {
    let areaSize = this.overlappingArea(rect);
    return areaSize !== 0 ? areaSize / this.size() : 0;
  }

  private size() {
    return (this.p2.x - this.p1.x) * (this.p2.y - this.p1.y);
  }

  private overlappingArea(r2: Rectangle): number {
    // check if there is no overlap
    if (this.p2.x < r2.p1.x || r2.p2.x < this.p1.x) return 0;
    if (this.p2.y < r2.p1.y || r2.p2.y < this.p1.y) return 0;

    // overlap exists. Let's find overlapping rectangle coordinates
    let overlapP1: Point = {
      x: Math.max(this.p1.x, r2.p1.x),
      y: Math.max(this.p1.y, r2.p1.y)
    }

    let overlapP2: Point = {
      x: Math.min(this.p2.x, r2.p2.x),
      y: Math.min(this.p2.y, r2.p2.y)
    }

    // Let's compute area
    return (overlapP2.x - overlapP1.x) * (overlapP2.y - overlapP1.y);
  }
}
