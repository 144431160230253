export class PageableList<T> {

  maxPage: number = 1;

  constructor(public list: T[], public page: number = 1, public pageSize: number = 10, public maxElements: number = 0) {
    this.maxPage = Math.max(Math.ceil(this.maxElements / this.pageSize), 1);
  }

  public isFirstPage(): boolean {
    return this.page <= 1;
  }

  public hasPreviousPage(): boolean {
    return !this.isFirstPage();
  }

  public isLastPage(): boolean {
    return this.page >= this.maxPage;
  }

  public hasNextPage(): boolean {
    return !this.isLastPage();
  }

  static from<T>(p: PageableList<T>, list: T[]): PageableList<T> {
    return new PageableList(list, p.page, p.pageSize, p.maxElements);
  }

}
