import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PageableList} from "../../../util/paging/pageable-list";
import {SortDirection, Sorting} from "../../../util/sorting/sorting";
import {ActivatedRoute} from "@angular/router";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {ExpedienceCourseService} from "../../services/expedience/ex-scheduled-course-service";
import {ExScheduledCourse} from "../../models/expedience/ex-scheduled-course";
import {IconNamesEnum} from "ngx-bootstrap-icons";

@Component({
  selector: 'app-schedule-course-list',
  templateUrl: './schedule-course-list.component.html',
  styleUrls: ['./schedule-course-list.component.scss']
})
export class ScheduleCourseListComponent implements OnInit, OnChanges {

  @Input()
  editing: boolean = false;

  allSelected: boolean = false;

  scheduleId: number = 0;

  scheduledCourses: PageableList<ExScheduledCourse> = new PageableList<ExScheduledCourse>([]);

  sortKey: string = 'name';
  direction: SortDirection = SortDirection.asc;

  constructor(private route: ActivatedRoute,
              private expedienceCourseService: ExpedienceCourseService,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.info(JSON.stringify(changes));
    let simpleChange = changes['editing'];
    if (!simpleChange.firstChange && !simpleChange.currentValue && simpleChange.previousValue) {
      console.log('Editieren beendet, lade die Daten neu für Seite ' + this.scheduledCourses.page);
      this.loadScheduledCourses(this.scheduledCourses.page);
    }
  }

  subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      let id: number = this.getScheduleIdFromString(params['id']);
      if (id > 0) {
        this.scheduleId = id;
        this.loadScheduledCourses(1);
      } else {
        this.allSelected = false;
        this.scheduledCourses = new PageableList<ExScheduledCourse>([]);
      }
    })
  }

  private getScheduleIdFromString(idParam: string | null) {
    return idParam ? (parseInt(idParam) || 0) : 0;
  }

  private loadScheduledCourses(page: number) {
    console.log('loadScheduledCourses for schedule ' + this.scheduleId);
    this.expedienceCourseService.findByScheduleIdPaged(
      this.scheduleId, page, this.scheduledCourses.pageSize, this.sortKey, this.direction).then(
      scheduledCourses => {
        this.updateAllSelected(scheduledCourses.list);
        this.scheduledCourses = PageableList.from(scheduledCourses, scheduledCourses.list);
      }
    );
  }

  private updateAllSelected(scheduledCourses: ExScheduledCourse[]) {
    this.allSelected = scheduledCourses
      .map(s => s.selected)
      .reduce((a, b) => a && b);
  }

  classSortingLable(columnName: string): string {
    return Sorting.getClassSortingLable(columnName, this.sortKey);
  }

  sortingDirectionButton(columnName: string, directionString: string): IconNamesEnum {
    return Sorting.getSortingDirectionButtonName(directionString, columnName, this.sortKey, this.direction);
  }

  handleSortingChange(columnName: string) {
    if (columnName === this.sortKey) {
      this.direction = this.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      this.sortKey = columnName;
      this.direction = columnName === 'date' ? SortDirection.desc : SortDirection.asc;
    }
    this.loadScheduledCourses(1);
  }

  firstPage() {
    this.loadScheduledCourses(1);
  }

  previousPage() {
    this.loadScheduledCourses(this.scheduledCourses.page - 1);
  }

  nextPage() {
    this.loadScheduledCourses(this.scheduledCourses.page + 1);
  }

  lastPage() {
    this.loadScheduledCourses(this.scheduledCourses.maxPage);
  }

  getPageSizeModel(): number[] {
    return [5, 10, 25];
  }

  updatePageSize(pageSize: number) {
    this.scheduledCourses.pageSize = pageSize;
    this.loadScheduledCourses(1)
  }

  isMember(): boolean {
    return this.loginInformationService.isAdmin();
  }

  changeStudentSelection() {
    this.updateAllSelected(this.scheduledCourses.list);
  }

  changeAllSelected() {
    this.scheduledCourses.list.forEach(s => s.selected = this.allSelected);
  }

  changeEditing(editing: boolean) {
    this.editing = editing;
    this.loadScheduledCourses(1);
  }

}
