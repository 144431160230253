import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'swg-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: InputFieldComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputFieldComponent
    }
  ]
})
export class InputFieldComponent implements OnInit, ControlValueAccessor, Validator {

  @Input()
  name?: string;

  @Input()
  lable?: string;

  @Input()
  type: string = 'text';

  @Input()
  editing: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  autocomplete: string = 'off';

  @Input()
  placeholder: string = '';

  private touched: boolean = false;

  _value: any;

  constructor() { }

  /* Methoden von OnInit */
  ngOnInit(): void {
    if (this.name == null) {
      throw new Error(InputFieldComponent.name + ' requires name!');
    }
    if (this.lable == null) {
      console.warn('lable in InputFieldComponent ' + this.name + ' is not set!');
    }
  }

  /* Methoden rundum ControlValueAccessor */
  writeValue(value: any): void {
    this._value = value;
  }

  // noinspection JSUnusedLocalSymbols
  private onChange = (value: any) => {};

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  private onTouched = () => {};

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onModelChanged(event: Event) {
    this._value = event;
    this.markAsTouched();
    this.onChange(this._value);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let value = control.value;
    if (this.required) {
      if (value == null) {
        return {
          mustBeProvided: this.name
        }
      }
    }
    return null;
  }

}
