import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Class} from "../models/class";
import {ServerConfig} from "../../config/server-config";

const apiUrl: string = '/api/class';

@Injectable({
  providedIn: 'root'
})
export class ClassAdapterService {

  private baseUrl;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  findAll(): Observable<Class[]> {
    return this.http.get<Class[]>(`${this.baseUrl}/`);
  }

  get(id: any): Observable<Class> {
    return this.http.get<Class>(`${this.baseUrl}/${id}`);
  }

  createOrUpdate(theClass: Class): Observable<Class> {
    if (theClass.id) {
      return this.update(theClass);
    }
    return this.create(theClass);
  }

  private create(theClass: Class): Observable<any> {
    return this.http.post(`${this.baseUrl}`, theClass);
  }

  private update(theClass: Class): Observable<any> {
    return this.http.put(`${this.baseUrl}/${theClass.id}`, theClass);
  }

  delete(theClass: Class): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${theClass.id}`);
  }

}
