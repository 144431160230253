import {Component, Input, OnInit} from '@angular/core';
import {MenuItemSeparator} from "./menu-item-separator";

@Component({
  selector: 'app-menu-item-separator',
  templateUrl: './menu-item-separator.component.html',
  styleUrls: ['./menu-item-separator.component.scss']
})
export class MenuItemSeparatorComponent implements OnInit {

  @Input() menuItemSeparator?: MenuItemSeparator;

  constructor() { }

  ngOnInit(): void {
  }

}
