import {SortDirection} from "../../util/sorting/sorting";
import {PageableList} from "../../util/paging/pageable-list";
import {HttpClient} from "@angular/common/http";
import {ServerConfig} from "../../config/server-config";
import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractPageableAdapterService<T> {

  private baseUrl: string;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + this.getApiUrl();
  }

  protected abstract getApiUrl(): string;

  protected findAllPagedInternal(page?: number, pageSize?: number, sortKey?: string, direction?: SortDirection, additionalParams?: Map<string, string>): Observable<PageableList<T>> {
    let hasParam: boolean = false;
    let url: string = `${this.baseUrl}/`;
    if (additionalParams) {
      for (const key of additionalParams.keys()) {
        url += this.paramSeparator(hasParam) + key + '=' + additionalParams.get(key);
        hasParam = true;
      }
    }
    if (page) {
      url += `${this.paramSeparator(hasParam)}page=${page}`;
      hasParam = true;
    }
    if (pageSize) {
      url += `${this.paramSeparator(hasParam)}pageSize=${pageSize}`;
      hasParam = true;
    }
    if (sortKey) {
      url += `${this.paramSeparator(hasParam)}sortKey=${sortKey}`;
      hasParam = true;
    }
    if (direction) {
      url += `${this.paramSeparator(hasParam)}direction=${direction}`;
      // noinspection JSUnusedAssignment
      hasParam = true;
    }
    return this.http.get<PageableList<T>>(url)
      .pipe(map((res: PageableList<T>) => PageableList.from(res, res.list.map(t => this.fixAttributes(t)))))
  }

  private paramSeparator(hasParam: boolean): string {
    return hasParam ? '&' : '?';
  }

  protected fixAttributes(t: T): T {
    return t;
  }

}
