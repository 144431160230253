import {Component, OnInit} from '@angular/core';
import {PageableList} from "../../../util/paging/pageable-list";
import {Schedule} from "../../../institution/models/schedule";
import {SortDirection, Sorting} from "../../../util/sorting/sorting";
import {Router} from "@angular/router";
import {IconNamesEnum} from "ngx-bootstrap-icons";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {SchedulePageableAdapterService} from "../../services/planning/schedule-pageable-adapter.service";

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {

  schedulesList: PageableList<Schedule> = new PageableList<Schedule>([]);

  sortKey: string = 'date';
  direction: SortDirection = SortDirection.desc;

  constructor(private router: Router,
              private schedulePageableAdapter: SchedulePageableAdapterService,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit() {
    this.loadSchedulesList(1);
  }

  private loadSchedulesList(page: number) {
    page = Math.min(Math.max(1, page), this.schedulesList.maxPage);
    this.schedulePageableAdapter.findAllPaged(page, this.schedulesList.pageSize, this.sortKey, this.direction)
      .subscribe({next: schedulesList => this.schedulesList = schedulesList});
  }

  onAdd() {
    this.router.navigate(['/new-schedule']);
  }

  onDetails(schedule: Schedule) {
    this.router.navigate(['/schedule', schedule.id]);
  }

  classSortingLable(columnName: string): string {
    return Sorting.getClassSortingLable(columnName, this.sortKey);
  }

  sortingDirectionButton(columnName: string, directionString: string): IconNamesEnum {
    return Sorting.getSortingDirectionButtonName(directionString, columnName, this.sortKey, this.direction);
  }

  handleSortingChange(columnName: string) {
    if (columnName === this.sortKey) {
      this.direction = this.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      this.sortKey = columnName;
      this.direction = columnName === 'date' ? SortDirection.desc : SortDirection.asc;
    }
    this.loadSchedulesList(1);
  }

  firstPage() {
    this.loadSchedulesList(1);
  }

  previousPage() {
    this.loadSchedulesList(this.schedulesList.page - 1);
  }

  nextPage() {
    this.loadSchedulesList(this.schedulesList.page + 1);
  }

  lastPage() {
    this.loadSchedulesList(this.schedulesList.maxPage);
  }

  getPageSizeModel(): number[] {
    return [5, 10, 25];
  }

  updatePageSize(pageSize: number) {
    this.schedulesList.pageSize = pageSize;
    this.loadSchedulesList(1)
  }

  isMember(): boolean {
    return this.loginInformationService.isAdmin();
  }

}
