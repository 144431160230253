import {Injectable} from '@angular/core';
import {Schedule} from "../../../institution/models/schedule";
import {AbstractPageableAdapterService} from "../../../core/services/abstract-pageable-adapter.service";
import {SortDirection} from "../../../util/sorting/sorting";
import {PageableList} from "../../../util/paging/pageable-list";
import {Observable} from "rxjs";

const apiUrl: string = '/api/pageable/schedule';

@Injectable({
  providedIn: 'root'
})
export class SchedulePageableAdapterService extends AbstractPageableAdapterService<Schedule>{
  protected getApiUrl(): string {
    return apiUrl;
  }

  findAllPaged(page?: number, pageSize?: number, sortKey?: string, direction?: SortDirection): Observable<PageableList<Schedule>> {
    return super.findAllPagedInternal(page, pageSize, sortKey, direction);
  }

  protected override fixAttributes(s: Schedule): Schedule {
    return Schedule.fixDateAttributes(s);
  }
}
