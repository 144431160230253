import {Component, OnInit} from '@angular/core';
import {PageableList} from "../../../../util/paging/pageable-list";
import {Student} from "../../../models/student";
import {SortDirection, Sorting} from "../../../../util/sorting/sorting";
import {ActivatedRoute, Router} from "@angular/router";
import {Class} from "../../../models/class";
import {ClassAdapterService} from "../../../services/class-adapter.service";
import {LoginInformationService} from "../../../../authentication/services/login-information.service";
import {IconNamesEnum} from "ngx-bootstrap-icons";
import {StudentPageableAdapterService} from "../../../services/student-pageable-adapter.service";

@Component({
  selector: 'app-class-students-list',
  templateUrl: './class-students-list.component.html',
  styleUrls: ['./class-students-list.component.scss']
})
export class ClassStudentsListComponent implements OnInit {

  classId: number = 0;
  theClass: Class = new Class(undefined, '', '');
  students: PageableList<Student> = new PageableList<Student>([]);

  sortKey: string = 'lastName';
  direction: SortDirection = SortDirection.asc;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginInformationService: LoginInformationService,
              private classAdapter: ClassAdapterService,
              private studentPageableAdapter: StudentPageableAdapterService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      let id: number = parseInt(params['id']) || 0;
      if (id) {
        this.classId = id;
        this.loadClassStudents(1);
      } else {
        this.students = new PageableList<Student>([]);
      }
    })
  }

  private loadClass(): Promise<Class> {
    return new Promise<Class>((resolve, reject) => {
      this.classAdapter.get(this.classId)
        .subscribe({
          next: theClass => resolve(theClass),
          error: error => {
            reject(`Fehler beim Laden der Klasse ${this.classId}: ${JSON.stringify(error)}`);
          }
        })
    });
  }

  private loadClassStudents(page: number) {
    if (this.theClass.id !== this.classId) {
      this.loadClass().then(theClass => {
        this.theClass = theClass;
        this.loadClassStudentsForClass(page)
      })
    } else {
      this.loadClassStudentsForClass(page);
    }
  }

  private loadClassStudentsForClass(page: number, theClass: Class = this.theClass) {
    this.studentPageableAdapter.findByClassPaged(
      theClass, page, this.students.pageSize, this.sortKey, this.direction)
      .subscribe({next: students => this.students = students});
  }

  onCreateStudent() {
    this.router.navigate(['class/' + this.classId + '/new-student']);
  }

  onDetails(student: Student) {
    this.router.navigate(['class/' + this.classId + '/student', student.id]);
  }

  classSortingLable(columnName: string): string {
    return Sorting.getClassSortingLable(columnName, this.sortKey);
  }

  sortingDirectionButton(columnName: string, directionString: string): IconNamesEnum {
    return Sorting.getSortingDirectionButtonName(directionString, columnName, this.sortKey, this.direction);
  }

  handleSortingChange(columnName: string) {
    if (columnName === this.sortKey) {
      this.direction = this.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      this.sortKey = columnName;
      this.direction = columnName === 'date' ? SortDirection.desc : SortDirection.asc;
    }
    this.loadClassStudents(this.students.page);
  }

  firstPage() {
    this.loadClassStudents(1);
  }

  previousPage() {
    this.loadClassStudents(this.students.page - 1);
  }

  nextPage() {
    this.loadClassStudents(this.students.page + 1);
  }

  lastPage() {
    this.loadClassStudents(this.students.maxPage);
  }

  getPageSizeModel(): number[] {
    return [5, 10, 25];
  }

  updatePageSize(pageSize: number) {
    this.students.pageSize = pageSize;
    this.loadClassStudents(1)
  }

  isAdmin(): boolean {
    return this.loginInformationService.isAdmin();
  }

}
