import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KonvaModule} from "ng2-konva";
import {PlanningRoutingModule} from "./planning-routing.module";
import {BoardViewComponent} from "./components/board-view/board-view.component";
import {ScheduleComponent} from './components/schedule/schedule.component';
import {ScheduleListComponent} from './components/schedule-list/schedule-list.component';
import {
  NgbAccordionModule,
  NgbAlert,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbInputDatepicker,
  NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import {NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {DateModule} from "../common/date/date.module";
import {ComponentsModule} from "../common/components/components.module";
import {FormsModule} from "@angular/forms";
import {ScheduleStudentListComponent} from './components/schedule-student-list/schedule-student-list.component';
import {ScheduleCourseListComponent} from './components/schedule-course-list/schedule-course-list.component';
import {IconsModule} from "../common/icons/icons.module";
import {RegistrationDetailsComponent} from './components/registration-details/registration-details.component';
import {
  RegistrationCourseListComponent
} from './components/registration-course-list/registration-course-list.component';
import {
  RegistrationStudentListComponent
} from './components/registration-student-list/registration-student-list.component';


@NgModule({
  declarations: [
    BoardViewComponent,
    ScheduleComponent,
    ScheduleListComponent,
    ScheduleStudentListComponent,
    ScheduleCourseListComponent,
    RegistrationDetailsComponent,
    RegistrationCourseListComponent,
    RegistrationStudentListComponent
  ],
  imports: [
    KonvaModule,
    CommonModule,
    PlanningRoutingModule,
    IconsModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbTooltip,
    NgxBootstrapIconsModule,
    DateModule,
    ComponentsModule,
    FormsModule,
    NgbAlert,
    NgbInputDatepicker,
    NgbAccordionModule
  ]
})
export class PlanningModule { }
