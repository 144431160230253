import {MenuItem} from "./menu-item";
import {Menu} from "./menu";
import {Role} from "../../../authentication/models/role";

export class MenuDropdown implements Menu {
  readonly id: number;
  readonly requiresRole?: Role;
  title: string;
  readonly items: MenuItem[];

  constructor(id: number, title: string, items: MenuItem[], requiresRole?: Role) {
    this.id = id;
    this.title = title;
    this.items = items;
    this.requiresRole = requiresRole;
    this.init();
  }

  init() {
    // macht erst einmal nichts ...
  }

  getType(): string {
    return "MENU_DROPDOWN";
  }

}
