import {Component, Input, OnInit} from '@angular/core';
import {ExpedienceStudentService} from "../../services/expedience/ex-scheduled-student-service";
import {ExScheduledStudent} from "../../models/expedience/ex-scheduled-student";

@Component({
  selector: 'app-registration-student-list',
  templateUrl: './registration-student-list.component.html',
  styleUrls: ['./registration-student-list.component.scss']
})
export class RegistrationStudentListComponent implements OnInit {

  @Input()
  scheduledCourseId: number = 0;

  scheduledStudents: ExScheduledStudent[] = [];

  constructor(private expedientStudentService: ExpedienceStudentService) {
  }

  ngOnInit(): void {
    this.loadStudents();
  }

  private loadStudents() {
    if (this.scheduledCourseId > 0) {
      this.expedientStudentService.findByScheduledCourseId(this.scheduledCourseId).then(
        s => this.scheduledStudents = s
      )
    } else {
      this.scheduledStudents = [];
    }
  }

}
