import {Component} from '@angular/core';
import {Navigationbar} from "./navigationbar";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {Router} from "@angular/router";
import {NavigationbarService} from "./navigationbar.service";
import {Menu} from "../menu/menu";
import {MenuButton} from "../menu/menu-button";
import {MenuDropdown} from "../menu/menu-dropdown";

@Component({
  selector: 'app-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.scss']
})
export class NavigationbarComponent {

  public collapsed = true;

  navigationbar: Navigationbar;

  constructor(private navigationbarService: NavigationbarService,
              private loginInformationService: LoginInformationService,
              private router: Router) {
    this.navigationbar = this.navigationbarService.getNavigationBar();
    this.navigationbar.observeMenuClicked().subscribe(
      () => this.collapsed = true
    );
  }

  getMenuButton(menu: Menu): MenuButton {
    return menu as MenuButton;
  }

  getMenuDropdown(menu: Menu): MenuDropdown {
    return menu as MenuDropdown;
  }

  onClick($event: Event) {
    $event.preventDefault();
    this.collapsed = true;
  }

  public isUserLoggedIn(): boolean {
    return this.loginInformationService.isAuthenticated();
  }

  onGoToLogout() {
    this.router.navigate(['/logout']);
  }

}
