import {Injectable} from '@angular/core';
import {Class} from "../models/class";
import {AbstractPageableAdapterService} from "../../core/services/abstract-pageable-adapter.service";
import {SortDirection} from "../../util/sorting/sorting";
import {PageableList} from "../../util/paging/pageable-list";
import {Observable} from "rxjs";

const apiUrl: string = '/api/pageable/class';

@Injectable({
  providedIn: 'root'
})
export class ClassPageableAdapterService extends AbstractPageableAdapterService<Class> {
  protected getApiUrl(): string {
    return apiUrl;
  }

  findAllPaged(page?: number, pageSize?: number, sortKey?: string, direction?: SortDirection): Observable<PageableList<Class>> {
    return super.findAllPagedInternal(page, pageSize, sortKey, direction);
  }

}
