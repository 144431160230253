import {Position} from "../../types/position";
import {Course} from "../../../institution/models/course";
import {Student} from "../../../institution/models/student";
import {ScheduledStudent} from "../core/scheduled-student";

export class ExScheduledStudent {
  id?: number;
  scheduleId : number;
  studentId: number;
  selected: boolean;
  drawX?: number;
  drawY?: number;

  // TODO the student should at some time be not nullable
  student?: Student;
  private _scheduledCourseId: number | null;
  course?: Course;

  constructor(id: number | undefined, scheduleId: number, studentId: number, selected: boolean, scheduledCourseId: number | null, drawX?: number, drawY?: number, student?: Student, course?: Course) {
    this.id = id;
    this.scheduleId = scheduleId;
    this.studentId = studentId;
    this.selected = selected;
    this.drawX = drawX;
    this.drawY = drawY;
    this._scheduledCourseId = scheduledCourseId;
    this.student = student;
    this.course = course;
  }

  getName(): string {
    return this.student !== undefined
      ? this.student.firstName + ' ' + this.student.lastName
      : `Name ${this.id}`;
  }

  getPosition(): Position | undefined {
    if (this.drawX && this.drawY) {
      return {
        x: this.drawX,
        y: this.drawY
      }
    }
    return undefined;
  }

  setPosition(position: Position) {
    this.drawX = position.x;
    this.drawY = position.y;
  }

  hasPosition() {
    return this.drawX !== null && this.drawY !== null;
  }


  set scheduledCourseId(value: number) {
    this._scheduledCourseId = value;
  }

  unsetScheduledCourseId() {
    this._scheduledCourseId = null;
  }

  asScheduledStudent(): ScheduledStudent {
    let scheduledStudent = new ScheduledStudent(
      this.id, this.studentId, this.selected, this.scheduleId, this._scheduledCourseId, this.drawX, this.drawY);
    console.log(`Werde nun updaten: ${JSON.stringify(scheduledStudent)}`);
    return scheduledStudent;
  }
}
