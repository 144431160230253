<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col" class="table-header col-one">
      Vorname
    </th>
    <th scope="col" class="table-header">
      Nachname
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let exStudent of scheduledStudents">
    <td class="col-one">{{exStudent.student?.firstName}}</td>
    <td>{{exStudent.student?.lastName}}</td>
  </tr>
  </tbody>
</table>
