import {MenuItem} from "./menu-item";
import {Role} from "../../../authentication/models/role";

export class MenuItemSeparator implements MenuItem {
  readonly id: number;
  readonly requiresRole?: Role = undefined;

  constructor(id: number) {
    this.id = id;
  }

  getType(): string {
    return "SEPARATOR";
  }

  public toString = () : string => {
    return `MenuItemSeparator (id: ${this.id})`;
  }

}
