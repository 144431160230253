<div class="class-subtable">
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col"
          class="table-header table-header-sortable col-one {{classSortingLable('firstName')}}"
          (click)="handleSortingChange('firstName')">
        <div class="sorting-header">
          <div class="sorting-lable">Vorname</div>
          <div class="sorting-toggle-buttons">
            <i-bs class="sorting"
                  [name]="sortingDirectionButton('firstName', 'asc')"></i-bs>
            <i-bs class="sorting"
                  [name]="sortingDirectionButton('firstName', 'desc')"></i-bs>
          </div>
        </div>
      </th>
      <th scope="col" style="width: max-content"
          class="table-header table-header-sortable {{classSortingLable('lastName')}}"
          (click)="handleSortingChange('lastName')">
        <div class="sorting-header">
          <div class="sorting-lable">Nachname</div>
          <div class="sorting-toggle-buttons">
            <i-bs class="sorting"
                  [name]="sortingDirectionButton('lastName', 'asc')"></i-bs>
            <i-bs class="sorting"
                  [name]="sortingDirectionButton('lastName', 'desc')"></i-bs>
          </div>
        </div>
      </th>
      <th scope="col" style="width: max-content"
          class="table-header table-header-sortable {{classSortingLable('dateOfBirth')}}"
          (click)="handleSortingChange('dateOfBirth')">
        <div class="sorting-header">
          <div class="sorting-lable">Geburtsdatum</div>
          <div class="sorting-toggle-buttons">
            <i-bs class="sorting"
                  [name]="sortingDirectionButton('dateOfBirth', 'asc')"></i-bs>
            <i-bs class="sorting"
                  [name]="sortingDirectionButton('dateOfBirth', 'desc')"></i-bs>
          </div>
        </div>
      </th>
    </tr>
    <tr>
      <th colspan="3" class="pageable-header">
        <div class="pageable-control-header">
          <div class="pageable-control"></div>
          <div class="pageable-lable">Seite {{students.page}} von {{students.maxPage}} ({{students.maxElements}})</div>
          <div class="pageable-control">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-dark pageable-button" id="page-size-button" ngbDropdownToggle>{{students.pageSize}}</button>
              <div ngbDropdownMenu aria-labelledby="page-size-button" class="pageable-drop-down">
                <div *ngFor="let pageSize of getPageSizeModel()">
                  <button class="pageable-drop-down-content" ngbDropdownItem (click)="updatePageSize(pageSize)">{{pageSize}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="pageable-control">
            <i-bs *ngIf="!students.isFirstPage()" class="pageable-active"
                  name="skip-start-fill"
                  ngbTooltip="Erste Seite" (click)="firstPage()"></i-bs>
            <i-bs *ngIf="students.isFirstPage()" class="pageable-deactive"
                  name="skip-start-fill"
                  ngbTooltip="Erste Seite"></i-bs>
          </div>
          <div class="pageable-control">
            <i-bs *ngIf="students.hasPreviousPage()" class="pageable-active"
                  name="caret-left-fill"
                  ngbTooltip="Vorige Seite" (click)="previousPage()"></i-bs>
            <i-bs *ngIf="!students.hasPreviousPage()" class="pageable-deactive"
                  name="caret-left-fill"
                  ngbTooltip="Vorige Seite"></i-bs>
          </div>
          <div class="pageable-control">
            <i-bs *ngIf="students.hasNextPage()" class="pageable-active"
                  name="caret-right-fill"
                  ngbTooltip="Nächste Seite" (click)="nextPage()"></i-bs>
            <i-bs *ngIf="!students.hasNextPage()" class="pageable-deactive"
                  name="caret-right-fill"
                  ngbTooltip="Nächste Seite"></i-bs>
          </div>
          <div class="pageable-control">
            <i-bs *ngIf="!students.isLastPage()" class="pageable-active"
                  name="skip-end-fill"
                  ngbTooltip="Letzte Seite" (click)="lastPage()"></i-bs>
            <i-bs *ngIf="students.isLastPage()" class="pageable-deactive"
                  name="skip-end-fill"
                  ngbTooltip="Letzte Seite"></i-bs>
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let student of students.list"
        class="clickable-row"
        (click)="onDetails(student)">
      <td class="col-one">{{student.firstName}}</td>
      <td>{{student.lastName}}</td>
      <!-- TODO hier muss eine Datumskonvertierung erfolgen! -->
      <td>{{student.dateOfBirth | localDate}}</td>
    </tr>
    </tbody>
  </table>
  <div>
    <button *ngIf="isAdmin()"
            id="button-create-exercise" class="editor-button btn btn-icon btn-icon-primary"
            placement="bottom-left" ngbTooltip="Schüler anlegen"
            (click)="onCreateStudent()"><i-bs name="plus-square-fill"></i-bs>
    </button>
  </div>
</div>
