<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col" style="width: max-content"
        class="table-header table-header-sortable col-one {{classSortingLable('date')}}"
        (click)="handleSortingChange('date')">
      <div class="sorting-header">
        <div class="sorting-lable">Datum</div>
        <div class="sorting-toggle-buttons">
          <i-bs class="sorting" [name]="sortingDirectionButton('date', 'asc')"></i-bs>
          <i-bs class="sorting" [name]="sortingDirectionButton('date', 'desc')"></i-bs>
        </div>
      </div>
    </th>
  </tr>
  <tr>
    <th colspan="5" class="pageable-header">
      <div class="pageable-control-header">
        <div class="pageable-control"></div>
        <div class="pageable-lable">Seite {{schedulesList.page}} von {{schedulesList.maxPage}} ({{schedulesList.maxElements}})</div>
        <div class="pageable-control">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-dark pageable-button" id="page-size-button" ngbDropdownToggle>{{schedulesList.pageSize}}</button>
            <div ngbDropdownMenu aria-labelledby="page-size-button" class="pageable-drop-down">
              <div *ngFor="let pageSize of getPageSizeModel()">
                <button class="pageable-drop-down-content" ngbDropdownItem (click)="updatePageSize(pageSize)">{{pageSize}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="!schedulesList.isFirstPage()" class="pageable-active"
                name="skip-start-fill"
                ngbTooltip="Erste Seite" (click)="firstPage()"></i-bs>
          <i-bs *ngIf="schedulesList.isFirstPage()" class="pageable-deactive"
                name="skip-start-fill"
                ngbTooltip="Erste Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="schedulesList.hasPreviousPage()" class="pageable-active"
                name="caret-left-fill"
                ngbTooltip="Vorige Seite" (click)="previousPage()"></i-bs>
          <i-bs *ngIf="!schedulesList.hasPreviousPage()" class="pageable-deactive"
                name="caret-left-fill"
                ngbTooltip="Vorige Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="schedulesList.hasNextPage()" class="pageable-active"
                name="caret-right-fill"
                ngbTooltip="Nächste Seite" (click)="nextPage()"></i-bs>
          <i-bs *ngIf="!schedulesList.hasNextPage()" class="pageable-deactive"
                name="caret-right-fill"
                ngbTooltip="Nächste Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="!schedulesList.isLastPage()" class="pageable-active"
                name="skip-end-fill"
                ngbTooltip="Letzte Seite" (click)="lastPage()"></i-bs>
          <i-bs *ngIf="schedulesList.isLastPage()" class="pageable-deactive"
                name="skip-end-fill"
                ngbTooltip="Letzte Seite"></i-bs>
        </div>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let schedule of schedulesList.list"
      class="clickable-row" (click)="onDetails(schedule)">
    <td class="col-one">{{schedule.date | localDate}}</td>
  </tr>
  </tbody>
</table>
<div class="table-buttons">
  <button *ngIf="isMember()" class="btn btn-icon btn-icon-primary"
          placement="bottom-left" ngbTooltip="Neuen Tag Planen"
          (click)="onAdd()"><i-bs name="plus-square-fill"></i-bs></button>
</div>
