import {Injectable} from '@angular/core';
import {ExScheduledStudent} from "../../models/expedience/ex-scheduled-student";
import {ScheduledStudentAdapterService} from "../core/scheduled-student-adapter.service";
import {ScheduledStudent} from "../../models/core/scheduled-student";
import {catchError, combineLatestAll, forkJoin, from, map, of} from "rxjs";
import {StudentAdapterService} from "../../../institution/services/student-adapter.service";
import {ScheduledCourseAdapterService} from "../core/scheduled-course-adapter.service";
import {ClassAdapterService} from "../../../institution/services/class-adapter.service";
import {Class} from "../../../institution/models/class";
import {Student} from "../../../institution/models/student";
import {SortDirection} from "../../../util/sorting/sorting";
import {PageableList} from "../../../util/paging/pageable-list";
import {ScheduledStudentPageableAdapterService} from "../core/scheduled-student-pageable-adapter.service";

@Injectable({
  providedIn: 'root'
})
export class ExpedienceStudentService {

  constructor(private scheduledStudentAdapter: ScheduledStudentAdapterService,
              private scheduledStudentPageableAdapter: ScheduledStudentPageableAdapterService,
              private studentAdapter: StudentAdapterService,
              private classAdapter: ClassAdapterService,
              private scheduledCourseAdapter: ScheduledCourseAdapterService) {
  }

  findByScheduleId(scheduleId: number): Promise<ExScheduledStudent[]> {
    return new Promise<ExScheduledStudent[]>((resolve) => {
      console.log('findByScheduleId(' + scheduleId + ')');
      this.scheduledStudentAdapter.findByScheduleId(scheduleId).subscribe(data => {
        console.log('Daten geladen: ' + JSON.stringify(data));
        if (data.length === 0) {
          resolve([]);
        } else {
          from(data).pipe(
            map(s => this.enrichExScheduledStudent(ScheduledStudent.map(s))),
            combineLatestAll()
          ).subscribe(students => {
            console.log('Angereicherte Daten: ' + JSON.stringify(students));
            resolve(students);
          });
        }
      })
    })
  }

  findByScheduledCourseId(scheduledCourseId: number): Promise<ExScheduledStudent[]> {
    return new Promise<ExScheduledStudent[]>((resolve) => {
      console.log('findByScheduledCourseId(' + scheduledCourseId + ')');
      this.scheduledStudentAdapter.findByScheduledCourseId(scheduledCourseId).subscribe(data => {
        console.log('Daten geladen: ' + JSON.stringify(data));
        if (data.length === 0) {
          resolve([]);
        } else {
          from(data).pipe(
            map(s => this.enrichExScheduledStudent(ScheduledStudent.map(s))),
            combineLatestAll()
          ).subscribe(students => {
            console.log('Angereicherte Daten: ' + JSON.stringify(students));
            resolve(students);
          });
        }
      })
    })
  }

  // TODO eine Möglichkeit, hier auf Observable umzustellen, ist die unten aufgeführte.
  //  Allerdings ist dann der Aufrufer genötigt, diesen Observable zu verarbeiten, was
  //  im Falle von "await <some-promise> nicht so ganz einfach ist.
  /*
  findByScheduleId(scheduleId: number): Observable<ExScheduledStudent[]> {
    return this.scheduledStudentAdapter.findByScheduleId(scheduleId).pipe(
      switchMap(async data => {
        const exs: ExScheduledStudent[] = await Promise.all(
          data.map(async (s) => {
            return await this.enrichExScheduledStudent(ScheduledStudent.map(s));
          }));
        return exs;
      })
    );
  }
   */

  findByScheduleIdPaged(scheduleId: number, page?: number, pageSize?: number, sortKey?: string,
                        direction?: SortDirection): Promise<PageableList<ExScheduledStudent>> {
    return new Promise<PageableList<ExScheduledStudent>>((resolve) => {
      this.scheduledStudentPageableAdapter.findByScheduleIdPaged(scheduleId, page, pageSize, sortKey, direction)
        .subscribe({
          next: data => {
            from(data.list).pipe(
              map(s => this.enrichExScheduledStudent(ScheduledStudent.map(s))),
              combineLatestAll()
            ).subscribe(students => {
              resolve(new PageableList<ExScheduledStudent>(students, data.page, data.pageSize, data.maxElements));
            });
          }
        });
    })
  }

  private enrichExScheduledStudent(scheduledStudent: ScheduledStudent): Promise<ExScheduledStudent> {
    return new Promise<ExScheduledStudent>((resolve) => {
      // TODO Fehlerbehandlung https://www.learnrxjs.io/learn-rxjs/operators/combination/forkjoin
      forkJoin({
        student: this.studentAdapter.get(scheduledStudent.studentId),
        scheduledCourse:
          scheduledStudent.scheduledCourseId !== undefined && scheduledStudent.scheduledCourseId !== null
          ? this.scheduledCourseAdapter.get(scheduledStudent.scheduledCourseId).pipe(catchError(() => of(undefined)))
          : of(undefined)
      }).subscribe(
        async data => {
          data.student.theClass = await this.loadClassForStudent(data.student);
          let student = new ExScheduledStudent(scheduledStudent.id, scheduledStudent.scheduleId, scheduledStudent.studentId, scheduledStudent.selected, scheduledStudent.scheduledCourseId, scheduledStudent.drawX, scheduledStudent.drawY, data.student);
          resolve(student);
        }
      );
    });
  }

  loadClassForStudent(student: Student): Promise<Class> {
    return new Promise<Class>((resolve => {
      this.classAdapter.get(student.classId).subscribe(theClass => {
        let class1 = new Class(theClass.id, theClass.grade, theClass.form);
        resolve(class1);
      })
    }))
  }

  updateStudent(student: ExScheduledStudent): Promise<void> {
    return new Promise<void>(resolve => {
      this.scheduledStudentAdapter.update(student.asScheduledStudent()).subscribe({next: () => resolve()});
    })
  }

}
