<div *ngIf="showMenuDropdown()">
  <a class="nav-link" tabindex="{{menuDropdown?.id}}" ngbDropdownToggle id="navbarDropdown{{menuDropdown?.id}}" role="button" >
    {{menuDropdown?.title}}
  </a>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'navbarDropdown' + menuDropdown?.id">
    <div *ngFor="let item of menuDropdown?.items">
      <div [ngSwitch]="item.getType()">
        <app-menu-item-link *ngSwitchCase="'LINK'" [menuItemLink]="getMenuItemLink(item)"></app-menu-item-link>
        <app-menu-item-separator *ngSwitchCase="'SEPARATOR'" [menuItemSeparator]="getMenuItemSeparator(item)"></app-menu-item-separator>
        <div *ngSwitchDefault>{{item.getType()}}</div>
      </div>
    </div>
  </div>
</div>
