<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div *ngIf="navigationbar.logo" class="navbar-logo">
    <a class="navbar-brand" routerLink="board-view"><img src="{{navigationbar.logo}}" alt="Logo"></a>
  </div>
  <div class="navbar-header">
    <div class="navbar-header-title-box">
      <div class="navbar-header-title">
        <div class="navbar-header-text">Ganztag</div>
        <div class="navbar-header-logo">
          <img *ngIf="navigationbar.headlineLogo" src="{{navigationbar.headlineLogo}}" alt="Logo">
        </div>
      </div>
    </div>
    <div class="navbar-menu navbar-header-item">
      <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
        <ul class="navbar-nav">
          <li class="navbar-item" ngbDropdown
              *ngFor="let menu of navigationbar.menuEntries">
            <div [ngSwitch]="menu.getType()">
              <app-menu-dropdown *ngSwitchCase="'MENU_DROPDOWN'"
                                 [menuDropdown]="getMenuDropdown(menu)"></app-menu-dropdown>
              <app-menu-button *ngSwitchCase="'MENU_BUTTON'" [menuButton]="getMenuButton(menu)"></app-menu-button>
              <div *ngSwitchDefault>{{menu.getType()}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <button class="navbar-toggler navbar-header-item" type="button" aria-controls="navbarContent"
          [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation"
          (click)="collapsed = !collapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

</nav>
