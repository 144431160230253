<div class="authentication-form">
  <div class="editor">
    <div class="editor-header">
      Ganztag - Abmeldung
    </div>
    <div *ngIf="infoMessage" class="editor-row">
      <div id="logout-info-message" class="info-message">{{infoMessage}}</div>
    </div>
    <div *ngIf="errorMessage" class="editor-row">
      <div id="logout-error-message" class="error-message">{{errorMessage}}</div>
    </div>
    <div *ngIf="isLoggedOut()" class="editor-button-row">
      <div class="editor-row">
<!--
        <button id="button-start" class="editor-button btn btn-icon btn-icon-primary"
                placement="bottom" ngbTooltip="Startseite"
                (click)="onGoToStart()">
          <i-bs name="journal-text"></i-bs>
        </button>
-->
        <button id="button-goto-login" class="editor-button btn btn-icon btn-icon-secondary"
                placement="bottom" ngbTooltip="Zurück zum Login"
                (click)="onGoToLogin()">
          <i-bs name="door-open-fill"></i-bs>
        </button>
      </div>
    </div>
  </div>
</div>
