import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ScheduledStudent} from "../../models/core/scheduled-student";
import {ServerConfig} from "../../../config/server-config";

const apiUrl: string = '/api/scheduledStudent';

@Injectable({
  providedIn: 'root'
})
export class ScheduledStudentAdapterService {

  private baseUrl;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  findAll(): Observable<ScheduledStudent[]> {
    return this.http.get<ScheduledStudent[]>(`${this.baseUrl}/`);
  }

  get(id: any): Observable<ScheduledStudent> {
    return this.http.get<ScheduledStudent>(`${this.baseUrl}/${id}`);
  }

  update(scheduledStudent: ScheduledStudent): Observable<any> {
    return this.http.put(`${this.baseUrl}/${scheduledStudent.id}`, scheduledStudent);
  }

  updateAll(scheduledStudents: ScheduledStudent[]): Observable<any> {
    console.log('Rufe ' + `${this.baseUrl}/updateAll` + ' auf.');
    return this.http.post(`${this.baseUrl}/updateAll`, scheduledStudents);
  }

  createAll(scheduledStudents: ScheduledStudent[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/createAll`, scheduledStudents);
  }

  findByScheduleId(scheduleId: number): Observable<ScheduledStudent[]> {
    return this.http.get<ScheduledStudent[]>(`${this.baseUrl}/?scheduleId=${scheduleId}`);
  }

  findByScheduledCourseId(scheduledCourseId: number): Observable<ScheduledStudent[]> {
    return this.http.get<ScheduledStudent[]>(`${this.baseUrl}/?scheduledCourseId=${scheduledCourseId}`);
  }

}
