import {Injectable} from '@angular/core';
import {Student} from "../models/student";
import {Class} from "../models/class";
import {SortDirection} from "../../util/sorting/sorting";
import {PageableList} from "../../util/paging/pageable-list";
import {AbstractPageableAdapterService} from "../../core/services/abstract-pageable-adapter.service";
import {Observable} from "rxjs";

const apiUrl: string = '/api/pageable/student';

@Injectable({
  providedIn: 'root'
})
export class StudentPageableAdapterService extends AbstractPageableAdapterService<Student> {

  protected override getApiUrl(): string {
    return apiUrl;
  }

  findByClassPaged(theClass: Class, page?: number, pageSize?: number, sortKey?: string, direction?: SortDirection): Observable<PageableList<Student>> {
    let additionalParams = new Map<string, string>();
    if (theClass.id) {
      additionalParams.set('classId', theClass.id.toString());
    }
    return super.findAllPagedInternal(page, pageSize, sortKey, direction, additionalParams);
  }


  protected override fixAttributes(s: Student): Student {
    return Student.fixDateAttributes(s);
  }
}
