import {DateTimeFormatter, LocalDate, LocalDateTime, ZoneId} from "@js-joda/core";
import '@js-joda/timezone'; // Just needs to be imported; registers itself automatically

export class LocalDateFormatter {

  private static readonly PATTERN_DATE: string = 'dd.MM.yyyy';
  private static readonly FORMAT_DATE: DateTimeFormatter
    = DateTimeFormatter.ofPattern(this.PATTERN_DATE);

/*
  private static readonly PATTERN_DATE_FOR_REST: string = 'yyyy-MM-dd';
  private static readonly FORMAT_DATE_FOR_REST: DateTimeFormatter
    = DateTimeFormatter.ofPattern(this.PATTERN_DATE_FOR_REST);
*/

  private static readonly PATTERN_DATE_TIME: string = 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'';
  private static readonly FORMAT_DATE_TIME: DateTimeFormatter
    = DateTimeFormatter.ofPattern(LocalDateFormatter.PATTERN_DATE_TIME)
  ;

  private static readonly ZONE_ID_GMT: ZoneId = ZoneId.of('GMT');
  private static readonly ZONE_ID_DE: ZoneId = ZoneId.of('Europe/Berlin');

  static format(date: LocalDate | undefined): string | undefined {
    return date ? date.format(LocalDateFormatter.FORMAT_DATE) : undefined;
  }

  static formatForRest(date: LocalDateTime | undefined): string | undefined {
    return date ? date.format(LocalDateFormatter.FORMAT_DATE_TIME) : undefined;
  }

  static parse(dateString: string | undefined): LocalDate | undefined {
    return dateString ? LocalDate.parse(dateString, LocalDateFormatter.FORMAT_DATE) : undefined;
  }

  public static toLocalDate(value: string | undefined): LocalDate | undefined {
    return value ? LocalDate.parse(value, LocalDateFormatter.FORMAT_DATE) : undefined;
  }

  public static toLocalDateTime(value: string | undefined): LocalDateTime | undefined {
    return value
      ? LocalDateTime
        .parse(value, LocalDateFormatter.FORMAT_DATE_TIME)
        .atZone(LocalDateFormatter.ZONE_ID_GMT)
        .withZoneSameInstant(LocalDateFormatter.ZONE_ID_DE)
        .toLocalDateTime()
      : undefined;
  }



}
