import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginInformationService} from "../../services/login-information.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  errorMessage?: string;
  infoMessage?: string;

  constructor(private router: Router,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit(): void {
    this.infoMessage = undefined;
    this.errorMessage = undefined;
    if (this.loginInformationService.isAuthenticated()) {
      // Wenn der Benutzer angemeldet ist, dann ausloggen
      this.loginInformationService.logout().then(logoutResponse => {
        if (logoutResponse.success) {
          this.infoMessage = "Abmeldung erfolgreich.";
        } else {
          let additionalMessage: string = logoutResponse.message
            ? `Fehler: ${logoutResponse.message}` : 'Bitte noch einmal versuchen!';
          this.errorMessage = `Abmeldung fehlgeschlagen. ${additionalMessage}`;
        }
      }).catch(e => {
        this.errorMessage = JSON.stringify(e);
      });
    } else {
      // Wenn der Benutzer gar nicht angemeldet ist, dann auf die Startseite weiterleiten
      this.infoMessage = "Sie waren schon abgemeldet.";
    }
  }

  public isLoggedOut(): boolean {
    return !this.loginInformationService.isAuthenticated();
  }

  onGoToLogin() {
    this.router.navigate(['/login']);
  }

  onGoToStart() {
    this.router.navigate(['/']);
  }
}
