import {NgModule} from '@angular/core';

import {
  arrow90DegDown,
  arrowClockwise,
  arrowLeftSquareFill,
  arrowRepeat,
  arrowsCollapse,
  arrowsExpand,
  bagFill,
  boxArrowInRight,
  cardImage,
  cardList,
  cardText,
  caretDown,
  caretDownFill,
  caretLeftFill,
  caretRightFill,
  caretUp,
  caretUpFill,
  chatQuoteFill,
  chatTextFill,
  checkSquareFill,
  cloudDownloadFill,
  cloudUploadFill,
  dashSquareFill,
  doorOpenFill,
  emojiSunglasses,
  exclamationOctagonFill,
  exclamationTriangleFill,
  fileEarmarkCheckFill,
  filePerson,
  handThumbsDown,
  handThumbsUp,
  houseDoorFill,
  icon1SquareFill,
  journalText,
  keyFill,
  listUl,
  mailbox2,
  NgxBootstrapIconsModule,
  pencil,
  peopleFill,
  personCheckFill,
  personDashFill,
  personFill,
  personPlusFill,
  plusSquareFill,
  questionCircle,
  shieldFillCheck,
  shieldFillExclamation,
  shieldLockFill,
  skipBackwardFill,
  skipEndFill,
  skipForwardFill,
  skipStartFill,
  threeDots,
  trashFill,
  xSquareFill
} from 'ngx-bootstrap-icons';

// Select some icons (use an object, not an array)
const icons = {
  arrowClockwise,
  arrowLeftSquareFill,
  arrowRepeat,
  arrow90DegDown,
  arrowsCollapse,
  arrowsExpand,
  bagFill,
  boxArrowInRight,
  cardImage,
  cardList,
  cardText,
  caretDown,
  caretDownFill,
  caretLeftFill,
  caretRightFill,
  caretUp,
  caretUpFill,
  chatQuoteFill,
  chatTextFill,
  checkSquareFill,
  cloudDownloadFill,
  cloudUploadFill,
  dashSquareFill,
  doorOpenFill,
  emojiSunglasses,
  exclamationOctagonFill,
  exclamationTriangleFill,
  fileEarmarkCheckFill,
  filePerson,
  handThumbsDown,
  handThumbsUp,
  houseDoorFill,
  icon1SquareFill,
  journalText,
  keyFill,
  listUl,
  mailbox2,
  pencil,
  peopleFill,
  personCheckFill,
  personDashFill,
  personFill,
  personPlusFill,
  plusSquareFill,
  shieldFillCheck,
  shieldFillExclamation,
  shieldLockFill,
  skipBackwardFill,
  skipEndFill,
  skipForwardFill,
  skipStartFill,
  threeDots,
  trashFill,
  questionCircle,
  xSquareFill
};

@NgModule({
  imports: [
    NgxBootstrapIconsModule.pick(icons, {
      width: '1em',
      height: '1em',
    })
  ],
  exports: [
    NgxBootstrapIconsModule
  ]
})
export class IconsModule {
}
