import {Injectable} from '@angular/core';
import {ScheduledCourse} from "../../models/core/scheduled-course";
import {SortDirection} from "../../../util/sorting/sorting";
import {PageableList} from "../../../util/paging/pageable-list";
import {AbstractPageableAdapterService} from "../../../core/services/abstract-pageable-adapter.service";
import {Observable} from "rxjs";

const apiUrl: string = '/api/pageable/scheduledCourse';

@Injectable({
  providedIn: 'root'
})
export class ScheduledCoursePageableAdapterService extends AbstractPageableAdapterService<ScheduledCourse> {

  protected override getApiUrl(): string {
    return apiUrl;
  }

  findByScheduleIdPaged(scheduleId: number, page?: number, pageSize?: number, sortKey?: string, direction?: SortDirection): Observable<PageableList<ScheduledCourse>> {
    let additionalParams = new Map<string, string>();
    additionalParams.set('scheduleId', scheduleId.toString());
    return super.findAllPagedInternal(page, pageSize, sortKey, direction, additionalParams);
  }

}
