import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {LoginInformationService} from "../services/login-information.service";

@Injectable({
  providedIn: 'root'
})
export class UserMemberGuard implements CanActivate {

  constructor(private loginInformationService: LoginInformationService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resolve => {
      if (this.loginInformationService.isMember()) {
        resolve(true);
      } else {
        resolve(this.router.parseUrl('/no-member'));
      }
    });
  }

}
