export class ScheduledCourse {
  id?: number;
  courseId: number;
  scheduleId : number;
  drawX?: number;
  drawY?: number;
  selected?: boolean;

  constructor(id: number | undefined, courseId: number, scheduleId: number, selected?: boolean, drawX?: number, drawY?: number) {
    this.id = id;
    this.courseId = courseId;
    this.scheduleId = scheduleId;
    this.selected = selected;
    this.drawX = drawX;
    this.drawY = drawY;
  }

  static map(c: ScheduledCourse): ScheduledCourse {
    let selected = c.selected === null ? false : c.selected;
    return new ScheduledCourse(c.id, c.courseId, c.scheduleId, c.selected, c.drawX, c.drawY);
  }


}
