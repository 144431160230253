import {Position} from "../../types/position";
import {Course} from "../../../institution/models/course";
import {ScheduledCourse} from "../core/scheduled-course";

export class ExScheduledCourse {
  id?: number;
  scheduleId : number;
  courseId: number;
  selected: boolean;
  drawX?: number;
  drawY?: number;

  // TODO this should at some time be not nullable
  course?: Course;

  constructor(id: number | undefined, scheduleId: number, courseId: number, selected: boolean, drawX?: number, drawY?: number, course?: Course) {
    this.id = id;
    this.scheduleId = scheduleId;
    this.courseId = courseId;
    this.selected = selected;
    this.drawX = drawX;
    this.drawY = drawY;
    this.course = course;
  }

  getPosition(): Position | undefined {
    if (this.drawX && this.drawY) {
      return {
        x: this.drawX,
        y: this.drawY
      }
    }
    return undefined;
  }

  setPosition(position: Position) {
    this.drawX = position.x;
    this.drawY = position.y;
  }

  hasPosition() {
    return this.drawX !== null && this.drawY !== null;
  }

  asScheduledCourse(): ScheduledCourse {
    return new ScheduledCourse(
      this.id, this.courseId, this.scheduleId, this.selected, this.drawX, this.drawY);
  }

  getId(): number {
    return this.id ? this.id : -1;
  }
}
