export class ScheduledStudent {
  id?: number;
  studentId: number;
  scheduleId: number;
  scheduledCourseId: number | null;
  selected: boolean;
  drawX?: number;
  drawY?: number;

  constructor(id: number | undefined, studentId: number, selected: boolean, scheduleId: number, scheduledCourseId: number | null, drawX?: number, drawY?: number) {
    this.id = id;
    this.studentId = studentId;
    this.scheduleId = scheduleId;
    this.scheduledCourseId = scheduledCourseId;
    this.drawX = drawX;
    this.drawY = drawY;
    this.selected = selected;
  }

  static map(s: ScheduledStudent): ScheduledStudent {
      let selected = s.selected === null ? false : s.selected;
      return new ScheduledStudent(s.id, s.studentId, selected, s.scheduleId, s.scheduledCourseId, s.drawX, s.drawY);
  }
}
