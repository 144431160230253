import {Component} from '@angular/core';
import {ExScheduledCourse} from "../../models/expedience/ex-scheduled-course";
import {ActivatedRoute} from "@angular/router";
import {ExpedienceCourseService} from "../../services/expedience/ex-scheduled-course-service";

@Component({
  selector: 'app-registration-course-list',
  templateUrl: './registration-course-list.component.html',
  styleUrls: ['./registration-course-list.component.scss']
})
export class RegistrationCourseListComponent {

  scheduleId: number = 0;

  scheduledCourses: ExScheduledCourse[] = [];
  collapsed: Map<number,boolean> = new Map<number, boolean>();
  collapsedAll: boolean = true;

  constructor(private route: ActivatedRoute,
              private expedienceCourseService: ExpedienceCourseService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      let id: number = this.getScheduleIdFromString(params['id']);
      if (id > 0) {
        this.scheduleId = id;
        this.loadScheduledCourses(1);
      } else {
        this.resetScheduledStudents();
      }
    })
  }

  private resetScheduledStudents() {
    this.scheduledCourses = [];
    this.resetCollapsed();
  }

  private resetCollapsed() {
    if (this.collapsed) {
      this.collapsed.clear()
    } else {
      this.collapsed = new Map<number, boolean>();
    }
  }

  private getScheduleIdFromString(idParam: string | null) {
    return idParam ? (parseInt(idParam) || 0) : 0;
  }

  private loadScheduledCourses(page: number) {
    console.log('loadScheduledCourses for schedule ' + this.scheduleId);
    this.expedienceCourseService.findByScheduleId(
      this.scheduleId, true).then(
      scheduledCourses => {
        this.scheduledCourses = scheduledCourses;
        this.resetCollapsed();
        for (const scheduledCourse of this.scheduledCourses) {
          this.collapsed.set(scheduledCourse.courseId, this.collapsedAll);
        }
      }
    );
  }

  switchCollapsedAll() {
    this.collapsedAll = !this.collapsedAll;
    for (const id of this.collapsed.keys()) {
      this.collapsed.set(id, this.collapsedAll);
    }
  }

  getCollapsed(courseId: number): boolean {
    let rowCollapsed = this.collapsed.get(courseId);
    return rowCollapsed !== undefined ? rowCollapsed : true;
  }
}
