import {Injectable} from '@angular/core';
import {Role} from '../models/role';
import {LoginResponse} from "../models/login-response";
import {LoginInformation} from "./login-information";
import {LogonState} from "../models/logon-state";
import {User} from "../models/user";
import {TokenStorageService} from "./token-storage.service";
import {AuthenticationAdapterService} from "../adapters/authentication-adapter.service";
import {LogoutResponse} from "../models/logout-response";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginInformationService {

  private readonly defaultLoginInformation: LoginInformation = {
    logonState: LogonState.signedOut,
    message: 'User is not logged in!',
    user: undefined
  };

  private loginInformation$: Subject<LoginInformation>;

  private redirectUrl?: string;

  constructor(private authenticationAdapter: AuthenticationAdapterService,
              private tokenStorageService: TokenStorageService) {
    this.loginInformation$ = new BehaviorSubject<LoginInformation>(this.getCurrenLoginInformation());
  }

  public getCurrenLoginInformation(): LoginInformation {
    let loginInformation = this.retrieveLoginInformation();
    return loginInformation ? loginInformation : this.defaultLoginInformation;
  }

  public getCurrentUserLogin(): string | undefined   {
    let user = this.getUser();
    return user ? user.username : undefined;
  }

  private getUser(): User | undefined {
    let loginInformation = this.getCurrenLoginInformation();
    return (loginInformation.logonState === LogonState.signedIn
        || loginInformation.logonState === LogonState.signedUp)
      && loginInformation.user ? loginInformation.user : undefined;
  }

  public isInGroup(userGroup: string): boolean {
    let user = this.getUser();
    if (user) {
      return user.roles && user.roles.includes(userGroup);
    }
    return false;
  }

  public isAdmin(): boolean {
    return this.isInGroup('ROLE_ADMIN');
  }

  public isMember(): boolean {
    return this.isInGroup('ROLE_MEMBER');
  }

  public isAuthenticated(): boolean {
    return this.getCurrenLoginInformation().logonState === LogonState.signedIn;
  }

  public login(username: string, password: string): Promise<LogonState> {
    return new Promise<LogonState>((resolve, reject) => {
      this.authenticationAdapter.login({
        username: username,
        password: password
      }).subscribe({
        next: loginResponse => {
          if (loginResponse.logonState === LogonState.signedIn) {
            this.tokenStorageService.saveUser(loginResponse);
          }
          if (loginResponse.logonState === LogonState.signedIn || loginResponse.logonState === LogonState.signedUp) {
            this.tokenStorageService.saveToken(loginResponse.user?.accessToken);
          }
          this.createAndStoreLoginInformation(loginResponse);
          resolve(loginResponse.logonState);
        },
        error: error => {
          this.createAndStoreErrorLoginInformation(error);
          reject(error);
        }});
    });
  }

  public logout(): Promise<LogoutResponse> {
    return new Promise<LogoutResponse>(resolve => {
      let loginInformation = this.getCurrenLoginInformation();
      if (loginInformation.logonState === LogonState.signedIn || loginInformation.logonState === LogonState.signedUp) {
        if (loginInformation.user) {
          console.log(`Versuche Benutzer ${loginInformation.user.username} auszuloggen.`)
          this.authenticationAdapter.logout({username: loginInformation.user.username}).subscribe({
            next: logoutResponse => {
                console.log(`Erfolgreiches logging out: ${JSON.stringify(logoutResponse)}`);
                this.clearLoginInformation(logoutResponse);
                resolve({success: true})
            },
            error: error => {
                console.log(`Fehler bei logout: ${error.message}`);
                let logoutResponse = {success: false, message: error.message};
                this.clearLoginInformation(logoutResponse);
                resolve(logoutResponse);
              }
          });
        } else {
          console.log(`LoginInformation ist nicht vollständig: username fehlt! ${JSON.stringify(loginInformation)}`);
          resolve({success: false, message: 'Username fehlt!'});
        }
      } else {
        // War gar nicht eingelogged - auch egal!
        resolve({success: true});
      }
    });
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  hasRedirectUrl(): boolean {
    return this.redirectUrl != undefined;
  }

  clearRedirectUrl(): string | undefined {
    let url = this.redirectUrl;
    this.redirectUrl = undefined;
    return url;
  }

  private createLoginInformation(loginResponse: LoginResponse): LoginInformation {
    return {
      logonState: loginResponse.logonState,
      message: loginResponse.message,
      user: loginResponse.user
    }
  }

  private createLoginInformationForLogout(logoutResponse: LogoutResponse): LoginInformation {
    return {
      logonState: LogonState.signedOut,
      message: logoutResponse.message || 'Erfolgreich abgemeldet',
      user: undefined
    }
  }

  private readonly LOGIN_INFORMATION_KEY = 'LoginInformationService:currentLoginInformation';

  private storeLoginInformation(loginInformation: LoginInformation) {
    localStorage.setItem(this.LOGIN_INFORMATION_KEY, JSON.stringify(loginInformation));
  }

  private retrieveLoginInformation(): LoginInformation | undefined {
    let jsonLoginInformation = localStorage.getItem(this.LOGIN_INFORMATION_KEY);
    return jsonLoginInformation ? LoginInformation.fromJSON(jsonLoginInformation) : undefined;
  }

  private clearLoginInformation(logoutResponse: LogoutResponse): void {
    this.publishLoginInformation(this.createLoginInformationForLogout(logoutResponse));
    localStorage.removeItem(this.LOGIN_INFORMATION_KEY);
  }

  private createAndStoreLoginInformation(loginResponse: LoginResponse) {
    let loginInformation = this.createLoginInformation(loginResponse);
    console.log(`LoginInformationService#createAndStoreLoginInformation -> ${JSON.stringify(loginInformation)}`)
    if (LoginInformationService.userChanged(loginInformation, this.retrieveLoginInformation())) {
      this.publishLoginInformation(loginInformation);
      this.storeLoginInformation(loginInformation);
    }
  }

  private static userChanged(newLoginInfo: LoginInformation, oldLoginInfo?: LoginInformation): boolean {
    let oldUserName = (oldLoginInfo && oldLoginInfo.user) ? oldLoginInfo.user.username : undefined;
    let newUserName = newLoginInfo.user ? newLoginInfo.user.username : undefined;

    let oldLogonState = oldLoginInfo ? oldLoginInfo.logonState : undefined;

    let changed: boolean = oldUserName !== newUserName
      || oldLogonState !== newLoginInfo.logonState;
    if (!changed) {
      let oldRoles = (oldLoginInfo && oldLoginInfo.user) ? oldLoginInfo.user.roles : undefined;
      let newRoles = newLoginInfo.user ? newLoginInfo.user.roles : undefined;
      if (oldRoles && newRoles) {
        changed = oldRoles.length != newRoles.length;
        // TODO hier müssen noch die Rollen verglichen werden
      }
    }
    return changed;
  }

  private createAndStoreErrorLoginInformation(error: any) {
    let loginInformation: LoginInformation =  {
      logonState: LogonState.invalid,
      message: error.message,
      user: undefined
    };
    console.log(`LoginInformationService#createAndStoreErrorLoginInformation -> ${JSON.stringify(loginInformation)}`)
    this.storeLoginInformation(loginInformation);
  }

  isHasRole(role: Role) {
    return role === Role.admin && this.isAdmin()
      || role === Role.member && (this.isMember() || this.isAdmin());
  }

  public observeLoginInformation(): Observable<LoginInformation> {
    return this.loginInformation$;
  }

  private publishLoginInformation(loginInformation: LoginInformation) {
    console.log('publishing loginInformation: ' + loginInformation);
    this.loginInformation$.next(loginInformation);
  }

}
