import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationRoutingModule} from "./authentication-routing.module";
import {LogoutComponent} from "./components/logout/logout.component";
import {LoginComponent} from "./components/login/login.component";
import {IconsModule} from "../common/icons/icons.module";
import {NgbAlert, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    IconsModule,
    NgbTooltip,
    ReactiveFormsModule,
    NgbAlert
  ],
  exports: [
  ]
})
export class AuthenticationModule { }
