import {Component, Input, OnInit} from '@angular/core';
import {MenuItemLink} from "./menu-item-link";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {LogonState} from "../../../authentication/models/logon-state";
import {LoginInformation} from "../../../authentication/services/login-information";

@Component({
  selector: 'app-menu-item-link',
  templateUrl: './menu-item-link.component.html',
  styleUrls: ['./menu-item-link.component.scss']
})
export class MenuItemLinkComponent implements OnInit {

  @Input() menuItemLink?: MenuItemLink;

  private logonState: LogonState = LogonState.signedOut;

  constructor(private loginInformationService: LoginInformationService) { }

  ngOnInit(): void {
    let information = this.loginInformationService.getCurrenLoginInformation();
    this.updateLogonState(information);
    this.loginInformationService.observeLoginInformation().subscribe(
      loginInformation => {
        this.updateLogonState(loginInformation)
      });

  }

  private updateLogonState(loginInformation: LoginInformation) {
    if (loginInformation) {
      this.logonState = loginInformation.logonState;
    } else {
      this.logonState = LogonState.signedOut;
    }
  }

  showMenuItem(): boolean {
    let acceptRole: boolean = !this.menuItemLink ||
      (this.menuItemLink.requiresRole === undefined
        || this.loginInformationService.isHasRole(this.menuItemLink.requiresRole));
    return acceptRole
      && (!this.menuItemLink || !this.menuItemLink.logonState || this.menuItemLink.logonState === this.logonState);
  }

  onClick(event: Event) {
    event.preventDefault();
    if (this.menuItemLink) {
      this.menuItemLink.publishMenuClicked();
    }
  }
}
