<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col" style="width: 10%"
        class="table-header col-one">
      <div class="sorting-header">
        <div class="sorting-lable">
          <input type="checkbox" [(ngModel)]="allSelected" (ngModelChange)="changeAllSelected()"
                 [disabled]="!editing" />
        </div>
        <!-- TODO Sortierung muss durch das Modell unterstützt werden -->
<!--
        <div class="sorting-toggle-buttons">
          <i-bs class="sorting" [name]="sortingDirectionButton('selected', 'asc')"></i-bs>
          <i-bs class="sorting" [name]="sortingDirectionButton('selected', 'desc')"></i-bs>
        </div>
-->
      </div>
    </th>
    <th scope="col" style="width: fit-content"
        class="table-header table-header-sortable {{classSortingLable('course.name')}}"
        (click)="handleSortingChange('course.name')">
      <div class="sorting-header">
        <div class="sorting-lable">Name</div>
        <!-- TODO Sortierung muss durch das Modell unterstützt werden -->
<!--
        <div class="sorting-toggle-buttons">
          <i-bs class="sorting" [name]="sortingDirectionButton('course.name', 'asc')"></i-bs>
          <i-bs class="sorting" [name]="sortingDirectionButton('course.name', 'desc')"></i-bs>
        </div>
-->
      </div>
    </th>
    <th scope="col" style="width: fit-content(30%)"
        class="table-header table-header-sortable {{classSortingLable('course.description')}}"
        (click)="handleSortingChange('course.description')">
      <div class="sorting-header">
        <div class="sorting-lable">Beschreibung</div>
        <!-- TODO Sortierung muss durch das Modell unterstützt werden -->
<!--
        <div class="sorting-toggle-buttons">
          <i-bs class="sorting" [name]="sortingDirectionButton('course.description', 'asc')"></i-bs>
          <i-bs class="sorting" [name]="sortingDirectionButton('course.description', 'desc')"></i-bs>
        </div>
-->
      </div>
    </th>
  <tr>
    <th colspan="5" class="pageable-header">
      <div class="pageable-control-header">
        <div class="pageable-control"></div>
        <div class="pageable-lable">Seite {{scheduledCourses.page}} von {{scheduledCourses.maxPage}} ({{scheduledCourses.maxElements}})</div>
        <div class="pageable-control">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-dark pageable-button" id="page-size-button" ngbDropdownToggle>{{scheduledCourses.pageSize}}</button>
            <div ngbDropdownMenu aria-labelledby="page-size-button" class="pageable-drop-down">
              <div *ngFor="let pageSize of getPageSizeModel()">
                <button class="pageable-drop-down-content" ngbDropdownItem (click)="updatePageSize(pageSize)">{{pageSize}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="!scheduledCourses.isFirstPage()" class="pageable-active"
                name="skip-start-fill"
                ngbTooltip="Erste Seite" (click)="firstPage()"></i-bs>
          <i-bs *ngIf="scheduledCourses.isFirstPage()" class="pageable-deactive"
                name="skip-start-fill"
                ngbTooltip="Erste Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="scheduledCourses.hasPreviousPage()" class="pageable-active"
                name="caret-left-fill"
                ngbTooltip="Vorige Seite" (click)="previousPage()"></i-bs>
          <i-bs *ngIf="!scheduledCourses.hasPreviousPage()" class="pageable-deactive"
                name="caret-left-fill"
                ngbTooltip="Vorige Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="scheduledCourses.hasNextPage()" class="pageable-active"
                name="caret-right-fill"
                ngbTooltip="Nächste Seite" (click)="nextPage()"></i-bs>
          <i-bs *ngIf="!scheduledCourses.hasNextPage()" class="pageable-deactive"
                name="caret-right-fill"
                ngbTooltip="Nächste Seite"></i-bs>
        </div>
        <div class="pageable-control">
          <i-bs *ngIf="!scheduledCourses.isLastPage()" class="pageable-active"
                name="skip-end-fill"
                ngbTooltip="Letzte Seite" (click)="lastPage()"></i-bs>
          <i-bs *ngIf="scheduledCourses.isLastPage()" class="pageable-deactive"
                name="skip-end-fill"
                ngbTooltip="Letzte Seite"></i-bs>
        </div>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let exCourse of scheduledCourses.list">
    <td class="col-one">
      <input type="checkbox" [(ngModel)]="exCourse.selected" (ngModelChange)="changeStudentSelection()"
             [disabled]="!editing"/>
    </td>
    <td>{{exCourse.course?.name}}</td>
    <td>{{exCourse.course?.description}}</td>
  </tr>
  </tbody>
</table>
