import {Component, Input, OnInit} from '@angular/core';
import {MenuDropdown} from "./menu-dropdown";
import {MenuItemLink} from "./menu-item-link";
import {MenuItemSeparator} from "./menu-item-separator";
import {MenuItem} from "./menu-item";
import {LoginInformationService} from "../../../authentication/services/login-information.service";

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss']
})
export class MenuDropdownComponent implements OnInit {

  @Input() menuDropdown?: MenuDropdown;

  constructor(public loginInformationService: LoginInformationService) { }

  ngOnInit(): void {
  }

  getMenuItemLink(menuItem: MenuItem): MenuItemLink {
    // TODO ist das etwas gefährlich - einfach in den Typen umwandeln?
    return menuItem as MenuItemLink;
  }

  getMenuItemSeparator(menuItem: MenuItem): MenuItemSeparator {
    // TODO ist das etwas gefährlich - einfach in den Typen umwandeln?
    return menuItem as MenuItemSeparator;
  }

  showMenuDropdown(): boolean {
    return !this.menuDropdown ||
      (this.menuDropdown.requiresRole === undefined
        || this.loginInformationService.isHasRole(this.menuDropdown.requiresRole));
  }
}
