import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? this.zeroFill(date.day, 2) + this.DELIMITER + this.zeroFill(date.month, 2) + this.DELIMITER + this.zeroFill(date.year, 4) : '';
  }

  zeroFill(number: number, width: number): string {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number.toString()) ? 2 : 1)).join('0') + number;
    }
    return number + ""; // always return a string
  }
}
