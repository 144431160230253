import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../environment/environment";

// import {environment} from "../../environment/environment";

@Injectable({
  providedIn: 'root'
})
export class ServerConfig {

  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private readonly serverUrl: string = environment.protocol + environment.host + ':' + environment.port;

  constructor() {
    console.info(`Using serverUrl ${this.serverUrl}`);
  }

  public getServerUrl() : string {
    return this.serverUrl;
  }

  public getHttpOptions() {
    return this.httpOptions;
  }

}
