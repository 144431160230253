import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Student} from "../models/student";
import {ServerConfig} from "../../config/server-config";

const apiUrl: string = '/api/student';

@Injectable({
  providedIn: 'root'
})
export class StudentAdapterService {

  private baseUrl: string;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  findAll(): Promise<Student[]> {
    return new Promise<Student[]>(resolve => {
      this.http.get<Student[]>(`${this.baseUrl}/`).subscribe(students => resolve(students));
    })
  }

  get(id: any): Observable<Student> {
    return this.http.get<Student>(`${this.baseUrl}/${id}`);
  }

  createOrUpdate(student: Student): Observable<Student> {
    if (student.id) {
      return this.update(student);
    }
    return this.create(student);
  }

  private create(student: Student): Observable<any> {
    return this.http.post(`${this.baseUrl}`, student);
  }

  private update(student: Student): Observable<any> {
    return this.http.put(`${this.baseUrl}/${student.id}`, student);
  }

  delete(student: Student): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${student.id}`);
  }

}
