import {LocalDate} from "@js-joda/core";

export class Schedule {
  id?: number;
  date: LocalDate;

  constructor(id: number | undefined, date: LocalDate) {
    this.id = id;
    this.date = date;
  }

  static fixDateAttributes(schedule: Schedule): Schedule {
    // noinspection SuspiciousTypeOfGuard
    let d: LocalDate | undefined = (schedule.date && typeof schedule.date === 'string')
      ? LocalDate.parse(schedule.date) : schedule.date;
    return new Schedule(schedule.id, d);
  }

}
