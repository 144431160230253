import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {UserAuthenticatedGuard} from "../../../authentication/helpers/user-authenticated.guard";
import {CoursesListComponent} from "./courses-list/courses-list.component";
import {CourseComponent} from "./course/course.component";

const routes: Routes = [
  {path: 'courses', component: CoursesListComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'course/:id', component: CourseComponent, canActivate: [UserAuthenticatedGuard]},
  {path: 'new-course', component: CourseComponent, canActivate: [UserAuthenticatedGuard]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class CoursesRoutingModule {
}
