import {Component, Input, OnInit} from '@angular/core';
import {Class} from "../../../models/class";
import {ActivatedRoute, Router} from "@angular/router";
import {ClassAdapterService} from "../../../services/class-adapter.service";
import {LoginInformationService} from "../../../../authentication/services/login-information.service";
import {StudentPageableAdapterService} from "../../../services/student-pageable-adapter.service";

@Component({
  selector: 'app-class-detail',
  templateUrl: './class-detail.component.html',
  styleUrls: ['./class-detail.component.scss']
})
export class ClassDetailComponent implements OnInit {

  @Input() entity: Class = this.createEmptyClass();

  editing: boolean = true;

  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private classesAdapter: ClassAdapterService,
              private studentPageableAdapter: StudentPageableAdapterService,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  private subscribeRouteParams(): void {
    this.route.params.subscribe(params => {
      let id: number = parseInt(params['id']) || 0;
      if (id !== 0) {
        this.loadClass(id);
        this.editing = false;
      } else {
        this.entity = this.createEmptyClass();
        this.editing = true;
      }
    })
  }

  private createEmptyClass(): Class {
    return new Class(undefined, '', '');
  }

  private loadClass(id: number): void {
    this.classesAdapter.get(id).subscribe(c => {
      this.entity = c;
    })
  }

  public onSave() {
    this.clearMessages();
    let isNew = this.isNew();
    this.classesAdapter.createOrUpdate(this.entity).subscribe(value => {
      console.log(JSON.stringify(value));
      // this.entity = value;
      // TODO das ist noch etwas gewagt - etwas mehr überprüfung könnte hier nicht schaden!
      this.entity = Object.assign(this.createEmptyClass(), value);
      this.editing = false;
      this.successMessage = `Klasse "${(this.entity.getDisplayName())}" wurde erfolgreich gespeichert.`;
      if (isNew) {
        this.router.navigate(['class', this.entity.id]);
      }
    });
  }

  public onCancel() {
    if (typeof this.entity.id !== 'number') {
      this.navigateToList();
    } else {
      this.editing = false;
      this.loadClass(this.entity.id);
    }
  }

  onDelete() {
    this.clearMessages();
    // TODO hier sollte man besser einen eigenen Service zum Zählen der Studenten einer Klasse bauen,
    //  anstatt alle Studenten zu laden um sie danach sofort wegzuschmeißen!
    this.studentPageableAdapter.findByClassPaged(this.entity)
      .subscribe({next: students => {
        let studentCount: number = students.maxElements;
        if (studentCount > 0) {
          this.errorMessage = `Die Klasse "${this.entity.getDisplayName()}" hat noch ${studentCount} Schüler und kann daher nicht gelöscht werden!`;
        } else {
          this.classesAdapter.delete(this.entity)
            .subscribe(result => {
              if (result === true) {
                this.navigateToList();
              } else {
                this.errorMessage = `Fehler beim Löschen der Klasse "${this.entity.getDisplayName()}".`;
              }
            })
        }
      }});
  }

  onGoToList() {
    this.navigateToList();
  }

  private navigateToList() {
    this.router.navigate(['/classes']);
  }

  isNew(): boolean {
    return typeof this.entity.id !== 'number';
  }

  isAdmin(): boolean {
    return this.loginInformationService.isAdmin();
  }

  private clearMessages() {
    this.successMessage = undefined;
    this.errorMessage = undefined;
  }

  closeError() {
    this.errorMessage = undefined;
  }

  closeSuccess() {
    this.successMessage = undefined;
  }

}
