<div class="input-field-row">
  <div *ngIf="editing && lable" class="input-lable">
    <label for="{{'input-' + name}}">{{lable}}</label>
  </div>
  <div *ngIf="editing" class="input-field">
    <input id="{{'input-' + name}}" type="text" autocomplete="{{autocomplete}}" [placeholder]="placeholder"
           (ngModelChange)="onModelChanged($event)"
           [(ngModel)]="_value"/>
  </div>
  <div *ngIf="!editing && lable" class="input-lable">
    <label for="{{'input-' + name + '-ro'}}">{{lable}}</label>
  </div>
  <div *ngIf="!editing" class="input-field">
    <div id="{{'input-' + name + '-ro'}}">{{_value}}</div>
  </div>
</div>
