<form class="authentication-form"
      [formGroup]="loginForm"
      (ngSubmit)="onLogin()">
  <div class="editor">
    <div class="editor-header">
      Ganztag - Anmeldung
    </div>
    <div class="editor-row">
      <!-- username -->
      <div class="editor-lable">
        <label for="input-username">Benutzername:</label>
      </div>
      <div class="editor-input">
        <input id="input-username" type="text" autocomplete="off"
               formControlName="username"/>
      </div>
    </div>
    <div class="editor-row">
      <!-- password -->
      <div class="editor-lable">
        <label for="input-password">Passwort:</label>
      </div>
      <div class="editor-input">
        <input id="input-password" type="password" autocomplete="off"
               formControlName="password"/>
      </div>
    </div>
    <div class="editor-button-row">
      <div class="editor-row">
        <button type="submit" class="editor-button btn btn-icon btn-icon-primary"
                placement="bottom-left" ngbTooltip="Login"
                [disabled]="!loginForm.valid"
        >
          <i-bs name="box-arrow-in-right"></i-bs>
        </button>
        <button class="editor-button btn btn-icon btn-icon-info"
                placement="bottom" ngbTooltip="Registrieren"
                (click)="onRegister()">
          <i-bs name="person-plus-fill"></i-bs>
        </button>
        <button *ngIf="!enhanced" class="editor-button btn btn-icon btn-icon-primary"
                placement="bottom" ngbTooltip="mehr ..."
                (click)="enhanced = true">
          <i-bs name="three-dots"></i-bs>
        </button>
        <button *ngIf="enhanced" class="editor-button btn btn-icon btn-icon-secondary"
                placement="bottom" ngbTooltip="weniger ..."
                (click)="enhanced = false">
          <i-bs name="three-dots"></i-bs>
        </button>
      </div>
      <div class="editor-row">
        <button *ngIf="enhanced" class="editor-button btn btn-icon btn-icon-danger"
                placement="bottom-left" ngbTooltip="Passwort zurücksetzen"
                (click)="onResetPassword()">
          <i-bs name="shield-fill-exclamation"></i-bs>
        </button>
        <button *ngIf="enhanced" class="editor-button btn btn-icon btn-icon-warning"
                placement="bottom-left" ngbTooltip="Neues Passwort bestätigen"
                (click)="onConfirmNewPassword()">
          <i-bs name="shield-fill-check"></i-bs>
        </button>
      </div>
    </div>
    <div *ngIf="errorMessage" class="editor-row">
      <ngb-alert [type]="'danger'" (closed)="closeErrors()">{{errorMessage}}</ngb-alert>
    </div>
  </div>
</form>
