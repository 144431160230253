import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationbarComponent} from './navigationbar/navigationbar.component';
import {RouterLink, RouterModule} from "@angular/router";
import {AuthenticationModule} from "../../authentication/authentication.module";
import {MenuDropdownComponent} from "./menu/menu-dropdown.component";
import {MenuItemSeparatorComponent} from "./menu/menu-item-separator.component";
import {MenuItemLinkComponent} from "./menu/menu-item-link.component";
import {MenuButtonComponent} from "./menu/menu-button.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {ClassesModule} from "../../institution/components/classes/classes.module";


@NgModule({
  declarations: [MenuButtonComponent, MenuDropdownComponent, MenuItemLinkComponent, MenuItemSeparatorComponent, NavigationbarComponent],
  exports: [
    MenuDropdownComponent,
    NavigationbarComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    RouterModule,
    RouterLink,
    AuthenticationModule,
    ClassesModule
  ]
})
export class MenuModule {
}
