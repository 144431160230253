import {LogonState} from "../models/logon-state";
import {User} from "../models/user";

export class LoginInformation {
  logonState: LogonState = LogonState.signedOut;
  message: string = 'Use is not logged in!';
  user?: User

  constructor(data: Partial<LoginInformation>) {
    Object.assign(this, data);
  }

  public static fromJSON = (json: string): LoginInformation => {
    const jsonObject = JSON.parse(json);
    return new LoginInformation(jsonObject);
  };
}
