import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {LoginRequest} from "../models/login-request";
import {catchError, Observable, throwError} from "rxjs";
import {ServerConfig} from "../../config/server-config";
import {LoginResponse} from "../models/login-response";
import {LogoutRequest} from "../models/logout-request";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationAdapterService {

  private authApi: string = '/api/auth/';

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    console.info(JSON.stringify(error));
    let message: string = error.status === 0
      ? 'Es ist ein Fehler im Browser aufgetreten. Bitte noch einmal versuchen!'
      : AuthenticationAdapterService.createStatusErrorMessage(error);
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(message));
  }

  private static createStatusErrorMessage(error: HttpErrorResponse): string {
    let errorText: string = (error.error && error.error.message) ? error.error.message : error.message;
    return `Status: ${error.status}, Fehler: ${errorText}`;
  }

  public login(request: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.serverConfig.getServerUrl() + this.authApi + 'login',
      request, this.serverConfig.getHttpOptions())
      .pipe(catchError(this.handleError));
  }

  public logout(request: LogoutRequest): Observable<any> {
    console.log(`Es versucht sich jemand abzumelden: ${JSON.stringify(request)}`);
    return this.http.post(this.serverConfig.getServerUrl() + this.authApi + 'logout',
      request, this.serverConfig.getHttpOptions())
      .pipe(catchError(this.handleError));
  }
}
