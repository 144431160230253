import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServerConfig} from "../../../config/server-config";

const apiUrl: string = '/planning';

@Injectable({
  providedIn: 'root'
})
export class PlanningAdapterService {

  private baseUrl;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  getLastSignificantChangeForSchedule(id: number): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/lastSignificantChangeForSchedule/${id}`);
  }

}
