import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServerConfig} from "../../config/server-config";
import {Course} from "../models/course";

const apiUrl: string = '/api/course';

@Injectable({
  providedIn: 'root'
})
export class CourseAdapterService {

  private baseUrl: string;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  findAll(): Observable<Course[]> {
    return this.http.get<Course[]>(`${this.baseUrl}/`);
  }

  get(id: any): Observable<Course> {
    return this.http.get<Course>(`${this.baseUrl}/${id}`);
  }

  createOrUpdate(course: Course): Observable<Course> {
    if (course.id) {
      return this.update(course);
    }
    return this.create(course);
  }

  private create(course: Course): Observable<any> {
    return this.http.post(`${this.baseUrl}`, course);
  }

  private update(course: Course): Observable<any> {
    return this.http.put(`${this.baseUrl}/${course.id}`, course);
  }



  delete(course: Course): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${course.id}`);
  }

}
