import {Component, OnInit} from '@angular/core';
import {PageableList} from "../../../../util/paging/pageable-list";
import {Class} from "../../../models/class";
import {SortDirection, Sorting} from "../../../../util/sorting/sorting";
import {LoginInformationService} from "../../../../authentication/services/login-information.service";
import {Router} from "@angular/router";
import {IconNamesEnum} from "ngx-bootstrap-icons";
import {ClassPageableAdapterService} from "../../../services/class-pageable-adapter.service";

@Component({
  selector: 'app-classes-list',
  templateUrl: './classes-list.component.html',
  styleUrls: ['./classes-list.component.scss']
})
export class ClassesListComponent implements OnInit {

  classesList: PageableList<Class> = new PageableList<Class>([]);

  sortKey: string = 'grade';
  direction: SortDirection = SortDirection.asc;

  constructor(private classPageableAdapter: ClassPageableAdapterService,
              private router: Router,
              private loginInformationService: LoginInformationService) {
  }

  async ngOnInit(): Promise<void> {
    this.loadClassesList(1);
  }

  private loadClassesList(page: number) {
    page = Math.min(Math.max(1, page), this.classesList.maxPage);
    this.classPageableAdapter.findAllPaged(page, this.classesList.pageSize, this.sortKey, this.direction)
      .subscribe({next: classesList => this.classesList = classesList});
  }

  onAdd() {
    this.router.navigate(['/new-class']);
  }

  onDetails(theClass: Class) {
    this.router.navigate(['/class', theClass.id]);
  }

  classSortingLable(columnName: string): string {
    return Sorting.getClassSortingLable(columnName, this.sortKey);
  }

  sortingDirectionButton(columnName: string, directionString: string): IconNamesEnum {
    return Sorting.getSortingDirectionButtonName(directionString, columnName, this.sortKey, this.direction);
  }

  handleSortingChange(columnName: string) {
    if (columnName === this.sortKey) {
      this.direction = this.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      this.sortKey = columnName;
      this.direction = SortDirection.asc;
    }
    this.loadClassesList(1);
  }

  firstPage() {
    this.loadClassesList(1);
  }

  previousPage() {
    this.loadClassesList(this.classesList.page - 1);
  }

  nextPage() {
    this.loadClassesList(this.classesList.page + 1);
  }

  lastPage() {
    this.loadClassesList(this.classesList.maxPage);
  }

  getPageSizeModel(): number[] {
    return [5, 10, 25];
  }

  updatePageSize(pageSize: number) {
    this.classesList.pageSize = pageSize;
    this.loadClassesList(1)
  }

  isAdmin(): boolean {
    return this.loginInformationService.isAdmin();
  }


}
