import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {KonvaModule} from "ng2-konva";

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {MenuModule} from "./common/menu/menu.module";
import {PageNotFoundComponent} from './common/page-not-found/page-not-found.component';
import {IconsModule} from "./common/icons/icons.module";
import {ComponentsModule} from "./common/components/components.module";
import {DateModule} from "./common/date/date.module";

import {AuthenticationModule} from "./authentication/authentication.module";
import {authInterceptorProviders} from './authentication/helpers/auth.interceptor';

import {FooterComponent} from "./common/footer/footer.component";
import {ClassesModule} from "./institution/components/classes/classes.module";
import {CoursesModule} from "./institution/components/courses/courses.module";
import {PlanningModule} from "./planning/planning.module";


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    KonvaModule,
    AuthenticationModule,
    FormsModule,
    DateModule,
    HttpClientModule,
    NgbModule,
    IconsModule,
    ComponentsModule,
    MenuModule,
    AuthenticationModule,
    ClassesModule,
    CoursesModule,
    PlanningModule,
    // Die Reihenfolge ist zu beachten - ansonsten funktionieren die Routen der einzelnen Module nicht
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [authInterceptorProviders, DateModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
