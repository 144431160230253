import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {LocalDatePipe} from "../../../common/date/local-date-pipe";
import {NgbDateAdapter, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {CustomDateAdapter} from "../../../common/adapter/ngb/custom-date-adapter";
import {CustomDateParserFormatter} from "../../../common/adapter/ngb/custom-date-parser-formatter";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";
import {Schedule} from "../../../institution/models/schedule";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginInformationService} from "../../../authentication/services/login-information.service";
import {ScheduleAdapterService} from "../../services/planning/schedule-adapter.service";
import {StudentAdapterService} from "../../../institution/services/student-adapter.service";
import {ScheduledCourseAdapterService} from "../../services/core/scheduled-course-adapter.service";
import {ScheduledStudent} from "../../models/core/scheduled-student";
import {ScheduledStudentAdapterService} from "../../services/core/scheduled-student-adapter.service";
import {CourseAdapterService} from "../../../institution/services/course-adapter.service";
import {ScheduledCourse} from "../../models/core/scheduled-course";
import {LocalDateFormatter} from "../../../common/date/local-date-formatter";
import {ScheduleStudentListComponent} from "../schedule-student-list/schedule-student-list.component";
import {ScheduleCourseListComponent} from "../schedule-course-list/schedule-course-list.component";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [
    LocalDatePipe,
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class ScheduleComponent implements OnInit, OnChanges {

  private readonly DATE_FORMATTER: DateTimeFormatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');

  @Input() schedule: Schedule = this.createEmptySchedule();

  @ViewChild(ScheduleStudentListComponent)
  private studentListComponent!: ScheduleStudentListComponent;

  @ViewChild(ScheduleCourseListComponent)
  private courseListComponent!: ScheduleCourseListComponent;

  editing: boolean = false;
  date: string | undefined;

  successMessage: string | undefined;
  errorMessage: string | undefined;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private scheduleAdapter: ScheduleAdapterService,
              private studentAdapter: StudentAdapterService,
              private courseAdapter: CourseAdapterService,
              private scheduledCourseAdapter: ScheduledCourseAdapterService,
              private scheduledStudentAdapter: ScheduledStudentAdapterService,
              private loginInformationService: LoginInformationService) {
  }

  ngOnInit(): void {
    this.subscribeRouteParams();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ScheduleComponent.ngOnChanges: ' + JSON.stringify(changes));
  }

  subscribeRouteParams(): void {
    this.route.params.subscribe(() => {
      this.loadSchedule()
        .then(() => console.log('Planung geladen.'))
        .catch(e => console.error('Fehler beim Laden einer Planung! ' + e));
    })
  }

  private loadSchedule(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let id = this.getScheduleIdFromRoute();
      if (id > 0) {
        this.scheduleAdapter.get(id).subscribe(result => {
          // TODO die Konvertierung sollte nicht von Hand passieren!
          this.schedule = Schedule.fixDateAttributes(result);
          this.date = this.schedule.date ? this.DATE_FORMATTER.format(this.schedule.date) : undefined;
          this.editing = false;
          resolve();
        }, error => {
          reject(error);
        })
      } else {
        this.schedule = this.createEmptySchedule();
        this.editing = true;
        resolve();
      }
    });
  }

  private getScheduleIdFromRoute() {
    // TODO warum gehe ich hier auf den Snapshot?
    let idParam = this.route.snapshot.paramMap.get('id');
    return idParam ? (parseInt(idParam) || 0) : 0;
  }

  async onSave() {
    this.clearMessages();
    let isNew = this.isNew();
    let localDate = this.date ? LocalDate.parse(this.date, this.DATE_FORMATTER) : undefined;
    if (!localDate) {
      this.errorMessage = 'Es muss ein Datum für die Planung angegeben werden';
    } else {
      this.schedule.date = localDate;
      if (isNew && await this.doesScheduleAlreadyExist(localDate)) {
        this.errorMessage = 'Es gibt schon einen geplanten Tag für den ' + LocalDateFormatter.format(localDate) + '!';
      } else {
        this.scheduleAdapter.createOrUpdate(this.schedule).subscribe(result => {
          this.schedule = Schedule.fixDateAttributes(result);
          if (isNew) {
            this.createScheduledStudentsAndScheduleCoursesForNewSchedule(this.schedule)
              .then(() => {
                this.editing = false;
                this.successMessage = 'Geplanter Tag wurde erfolgreich gespeichert.';
                this.router.navigate(['/schedule/', this.schedule.id]);
              })
          } else {
            this.updateScheduledStudentsAndScheduledCoursesForSchedule(this.schedule)
              .then(() => {
                this.editing = false;
                this.successMessage = 'Geplanter Tag wurde erfolgreich aktualisiert.';
              });
          }
        }, error => {
          this.errorMessage = `Fehler beim Speichern der Planung: ${JSON.stringify(error)}`;
          console.log(JSON.stringify(error));
        })
      }
    }
  }

  private doesScheduleAlreadyExist(localDate: LocalDate): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.scheduleAdapter.findByDate(localDate).subscribe( schedules => {
        resolve(schedules && schedules.length > 0);
      })
    });
  }

  onCancel() {
    if (this.isNew()) {
      this.navigateToList();
    } else {
      this.editing = false;
      this.loadSchedule()
        .then(() => console.log('Planung geladen.'))
        .catch(e => console.error('Fehler beim Laden einer Planung! ' + e));
    }
  }

  onDelete() {
    this.clearMessages();
    this.scheduleAdapter.delete(this.schedule).subscribe(
      result => {
        if (result === true) {
          this.navigateToList();
        } else {
          this.errorMessage = 'Planung konnte nicht gelöscht werden!';
        }
      }
    );
  }

  onGoToList() {
    this.navigateToList();
  }

  private clearMessages() {
    this.errorMessage = undefined;
    this.successMessage = undefined;
  }

  closeError() {
    this.errorMessage = undefined;
  }

  closeSuccess() {
    this.successMessage = undefined;
  }

  private navigateToList() {
    this.router.navigate(['/schedules']);
  }

  isNew(): boolean {
    return typeof this.schedule.id !== 'number';
  }


  isAdmin(): boolean {
    return this.loginInformationService.isAdmin();
  }

  private createEmptySchedule() {
    return new Schedule(undefined, LocalDate.now());
  }

  private createScheduledStudentsAndScheduleCoursesForNewSchedule(schedule: Schedule): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.createScheduledStudentsForNewSchedule(schedule)
        .then(() => {
          this.createScheduledCoursesForNewSchedule(schedule)
            .then(() => {
              console.info('Hat anscheinend alles geklappt mit den Schülern und Kursen.');
              resolve();
            })
            .catch(err => {
              console.error(err);
              this.errorMessage = 'Fehler beim Erzeugen des geplanten Tages: Kurse konnten nicht angelegt werden.';
              reject();
            });
        })
        .catch(err => {
          console.error(err);
          this.errorMessage = 'Fehler beim Erzeugen des geplanten Tages: Schüler konnten nicht angelegt werden.';
          reject();
        });
    });
  }

  private createScheduledStudentsForNewSchedule(schedule: Schedule): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (schedule.id === undefined) {
        reject();
      } else {
        let scheduleId: number = schedule.id;
        this.studentAdapter.findAll().then(students => {
          let scheduledStudents: ScheduledStudent[] = [];
          for (const student of students) {
            if (student.id) {
              scheduledStudents.push(
                new ScheduledStudent(undefined, student.id, true, scheduleId, null));
            }
          }
          if (scheduledStudents.length >= 0) {
            this.scheduledStudentAdapter.createAll(scheduledStudents).subscribe(() => {
              resolve();
            }, err => {
              console.error(err);
              this.errorMessage = 'Fehler beim Speichern der eingeplanten Schüler.';
              reject();
            });
          } else {
            resolve();
          }
        })
      }
    });
  }

  private createScheduledCoursesForNewSchedule(schedule: Schedule): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (schedule.id === undefined) {
        reject();
      } else {
        let scheduleId: number = schedule.id;
        this.courseAdapter.findAll().subscribe(courses => {
          let scheduledCourses: ScheduledCourse[] = [];
          for (const course of courses) {
            if (course.id) {
              scheduledCourses.push(new ScheduledCourse(undefined, course.id, scheduleId, true));
            }
          }
          if (scheduledCourses.length >= 0) {
            this.scheduledCourseAdapter.createAll(scheduledCourses).subscribe(() => {
              resolve();
            }, err => {
              console.error(err);
              this.errorMessage = 'Fehler beim Speichern der eingeplanten Schüler.';
              reject();
            });
          }
        })
      }
    })
  }

  private updateScheduledStudentsAndScheduledCoursesForSchedule(schedule: Schedule): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.updateScheduledStudentsForSchedule(schedule)
        .then(() => {
          this.updateScheduledCoursesForSchedule(schedule)
            .then(() => {
              console.info('Hat anscheinend alles geklappt mit den Schülern und Kursen.');
              resolve();
            })
            .catch(err => {
              console.error(err);
              this.errorMessage = 'Fehler beim Aktualisieren des geplanten Tages: Kurse konnten nicht angelegt werden.';
              reject();
            });
        })
        .catch(err => {
          console.error(err);
          this.errorMessage = 'Fehler beim Aktualisieren des geplanten Tages: Schüler konnten nicht angelegt werden.';
          reject();
        });
    });
  }
  private updateScheduledStudentsForSchedule(schedule: Schedule): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let scheduledStudents: ScheduledStudent[] = [];
      for (const s of this.studentListComponent.scheduledStudents.list) {
        if (s.id) {
          scheduledStudents.push(s.asScheduledStudent());
        }
      }
      console.log('Aktualisiere Schüler für geplanten Tag: ' + JSON.stringify(scheduledStudents));
      if (scheduledStudents.length >= 0) {
        this.scheduledStudentAdapter.updateAll(scheduledStudents).subscribe(() => {
          resolve();
        }, err => {
          console.error(err);
          this.errorMessage = 'Fehler beim Aktualisieren der eingeplanten Schüler.'
          reject();
        });
      } else {
        resolve();
      }
    });
  }

  private updateScheduledCoursesForSchedule(schedule: Schedule): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let scheduledCourse: ScheduledCourse[] = [];
      for (const s of this.courseListComponent.scheduledCourses.list) {
        if (s.id) {
          scheduledCourse.push(s.asScheduledCourse());
        }
      }
      if (scheduledCourse.length >= 0) {
        this.scheduledCourseAdapter.updateAll(scheduledCourse).subscribe(() => {
          resolve();
        }, err => {
          console.error(err);
          this.errorMessage = 'Fehler beim Aktualisieren der eingeplanten Schüler.'
          reject();
        });
      } else {
        resolve();
      }
    });
  }

  showRegistrationDetails() {
    this.router.navigate(['/registration-details/', this.schedule.id]);
  }
}
