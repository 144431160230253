import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {
  NgbAlert,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbInputDatepicker,
  NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import {ComponentsModule} from "../../../common/components/components.module";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {DateModule} from "../../../common/date/date.module";
import {CoursesListComponent} from "./courses-list/courses-list.component";
import {CoursesRoutingModule} from "./courses-routing.module";
import {CourseComponent} from './course/course.component';


@NgModule({
  declarations: [
    CoursesListComponent,
    CourseComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxBootstrapIconsModule,
    ComponentsModule,
    NgbAlert,
    CoursesRoutingModule,
    DateModule,
    NgbInputDatepicker,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbTooltip
  ]
})
export class CoursesModule {
}
