import {Component, OnInit} from '@angular/core';
// @ts-ignore
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  copyright!: string;
  version!: string;

  constructor() { }

  ngOnInit(): void {
    this.copyright = '\u00A9 2023, Stefan Hedtfeld'
    this.version = packageJson.version;
  }

}
