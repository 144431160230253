<div class="editor">
  <!-- -->
  <div class="editor-row">
    <!-- Titel -->
    <div class="editor-header">
      Geplanter Tag
    </div>
  </div>
  <!-- -->
  <div>
    <button id="button-registration-details" class="editor-button btn btn-icon btn-icon-secondary"
            placement="bottom-left" ngbTooltip="{{editing ? 'Anmeldungen (Editieren beenden)' : 'Anmeldungen'}}" [disabled]="editing"
            (click)="showRegistrationDetails()">
      <i-bs name="card-text"></i-bs>
    </button>
  </div>
  <div *ngIf="!editing" class="editor-row">
    <!-- date read-only -->
    <div class="editor-lable">
      <label for="input-date-ro">Datum:</label>
    </div>
    <div id="input-date-ro" class="editor-input editor-readonly">{{schedule.date | localDate}}</div>
  </div>
  <div *ngIf="editing" class="editor-row">
    <!-- date -->
    <div class="editor-lable">
      <label for="input-date">Datum:</label>
    </div>
    <div class="editor-input">
      <div class="editor-datepicker">
        <input id="input-date" placeholder="TT.MM.JJJJ"
               name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker"
               autocomplete="off"/>
        <div class="editor-datepicker-button">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isNew()">
    <div ngbAccordion>
      <!-- Bereich für die Schüler -->
      <div ngbAccordionItem [collapsed]="false">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="schedule-sublist-button">Schüler</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <app-schedule-student-list [editing]=editing></app-schedule-student-list>
          </div>
        </div>
      </div>
      <!-- Bereich für die Kurse -->
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="schedule-sublist-button">Kurse</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <app-schedule-course-list [editing]=editing></app-schedule-course-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="editor-row">
    <div *ngIf="editing">
      <button *ngIf="isAdmin()"
              id="button-save" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Speichern"
              (click)="onSave()">
        <i-bs name="file-earmark-check-fill"></i-bs>
      </button>
      <button id="button-cancel" class="editor-button btn btn-icon btn-icon-secondary"
              placement="bottom" ngbTooltip="Abbrechen"
              (click)="onCancel()">
        <i-bs name="x-square-fill"></i-bs>
      </button>
    </div>
    <div *ngIf="!editing">
      <button *ngIf="isAdmin()"
              id="button-edit" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Bearbeiten"
              (click)="editing = !editing">
        <i-bs name="pencil"></i-bs>
      </button>
      <button *ngIf="isAdmin()"
              id="button-delete" class="editor-button btn btn-icon btn-icon-danger"
              placement="bottom" ngbTooltip="Löschen"
              (click)="onDelete()">
        <i-bs name="trash-fill"></i-bs>
      </button>
      <button id="button-back" class="editor-button btn btn-icon btn-icon-primary"
              placement="bottom-left" ngbTooltip="Zurück zur Liste"
              (click)="onGoToList()">
        <i-bs name="caret-left-fill"></i-bs>
      </button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="editor-row">
    <ngb-alert [type]="'warning'" (closed)="closeError()">{{errorMessage}}</ngb-alert>
  </div>
  <div *ngIf="successMessage" class="editor-row">
    <ngb-alert [type]="'success'" (closed)="closeSuccess()">{{successMessage}}</ngb-alert>
  </div>
</div>
