import {Component, OnInit} from '@angular/core';
import {PageableList} from "../../../../util/paging/pageable-list";
import {Course} from "../../../models/course";
import {SortDirection, Sorting} from "../../../../util/sorting/sorting";
import {Router} from "@angular/router";
import {IconNamesEnum} from "ngx-bootstrap-icons";
import {LoginInformationService} from "../../../../authentication/services/login-information.service";
import {CoursePageableAdapterService} from "../../../services/course-pageable-adapter.service";

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  coursesList: PageableList<Course> = new PageableList<Course>([]);

  sortKey: string = 'name';
  direction: SortDirection = SortDirection.asc;

  constructor(private router: Router,
              private coursePageableAdapter: CoursePageableAdapterService,
              private loginInformationService: LoginInformationService) {
  }

  async ngOnInit(): Promise<void> {
    this.loadCoursesList(1);
  }

  private loadCoursesList(page: number) {
    page = Math.min(Math.max(1, page), this.coursesList.maxPage);
    this.coursePageableAdapter.findAllPaged(page, this.coursesList.pageSize, this.sortKey, this.direction)
      .subscribe({next: coursesList => this.coursesList = coursesList});
  }

  onAdd() {
    this.router.navigate(['/new-course']);
  }

  onDetails(course: Course) {
    this.router.navigate(['/course', course.id]);
  }

  classSortingLable(columnName: string): string {
    return Sorting.getClassSortingLable(columnName, this.sortKey);
  }

  sortingDirectionButton(columnName: string, directionString: string): IconNamesEnum {
    return Sorting.getSortingDirectionButtonName(directionString, columnName, this.sortKey, this.direction);
  }

  handleSortingChange(columnName: string) {
    if (columnName === this.sortKey) {
      this.direction = this.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      this.sortKey = columnName;
      this.direction = SortDirection.asc;
    }
    this.loadCoursesList(this.coursesList.page);
  }

  firstPage() {
    this.loadCoursesList(1);
  }

  previousPage() {
    this.loadCoursesList(this.coursesList.page - 1);
  }

  nextPage() {
    this.loadCoursesList(this.coursesList.page + 1);
  }

  lastPage() {
    this.loadCoursesList(this.coursesList.maxPage);
  }

  getPageSizeModel(): number[] {
    return [5, 10, 25];
  }

  updatePageSize(pageSize: number) {
    this.coursesList.pageSize = pageSize;
    this.loadCoursesList(1)
  }

  isMember(): boolean {
    return this.loginInformationService.isAdmin();
  }

}
