import {IconNamesEnum} from "ngx-bootstrap-icons";

export class Sorting {

  public static compareValues(key: any, direction: SortDirection = SortDirection.asc, nullSorting: NullSorting = NullSorting.last) {
    return function innerSort({a, b}: { a: any, b: any }) {
      if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA === null || varA === undefined) {
        comparison = nullSorting === NullSorting.last ? 1 : -1;
      } else if (varB === null || varB === undefined) {
        comparison = nullSorting === NullSorting.first ? 1 : -1
      } else if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (direction === SortDirection.desc) ? (comparison * -1) : comparison
      );
    };
  }

  public static getClassSortingLable(columnName: string, sortKey: string) {
    return columnName === sortKey ? 'table-header-sorted' : 'table-header';
  }

  public static getSortingDirectionButtonName(directionString: string, columnName: string, sortKey: string, direction: SortDirection): IconNamesEnum {
    let sortDirection: SortDirection = Sorting.getDirection(directionString);
    let useFill: boolean = columnName === sortKey && direction === sortDirection;
    if (sortDirection === SortDirection.asc) {
      return useFill ? IconNamesEnum.CaretUpFill : IconNamesEnum.CaretUp;
    } else {
      return useFill ? IconNamesEnum.CaretDownFill : IconNamesEnum.CaretDown;
    }
  }

  public static getDirection(direction: string): SortDirection {
    return direction === 'asc' ? SortDirection.asc : SortDirection.desc;
  }



}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum NullSorting {
  first,
  last
}
