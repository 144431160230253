import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[2], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[0], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ?
      this.zeroFill(date.year, 4)
      + this.DELIMITER + this.zeroFill(date.month, 2)
      + this.DELIMITER + this.zeroFill(date.day, 2)
      : '';
  }

  zeroFill(number: number, width: number): string {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number.toString()) ? 2 : 1)).join('0') + number;
    }
    return number + ""; // always return a string
  }
}
