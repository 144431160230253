import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Schedule} from "../../../institution/models/schedule";
import {ServerConfig} from "../../../config/server-config";
import {LocalDate} from "@js-joda/core";
import {LocalDateFormatter} from "../../../common/date/local-date-formatter";

const apiUrl: string = '/api/schedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleAdapterService {

  private baseUrl: string;

  constructor(private http: HttpClient, private serverConfig: ServerConfig) {
    this.baseUrl = this.serverConfig.getServerUrl() + apiUrl;
  }

  findAll(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(`${this.baseUrl}/`);
  }

  get(id: any): Observable<Schedule> {
    return this.http.get<Schedule>(`${this.baseUrl}/${id}`);
  }

  createOrUpdate(schedule: Schedule): Observable<Schedule> {
    if (schedule.id) {
      return this.update(schedule);
    }
    return this.create(schedule);
  }

  private create(schedule: Schedule): Observable<any> {
    return this.http.post(`${this.baseUrl}`, schedule);
  }

  private update(schedule: Schedule): Observable<any> {
    return this.http.put(`${this.baseUrl}/${schedule.id}`, schedule);
  }

  delete(schedule: Schedule): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${schedule.id}`);
  }

  findByDate(date: LocalDate): Observable<Schedule[]> {
    // TODO dies ist erst einmal ein Workaround, um das Datum in der Datenbank korrekt abzufragen
    let dateStr = LocalDateFormatter.formatForRest(date.atStartOfDay());
    console.log('Date is now ' + dateStr);
    return this.http.get<Schedule[]>(`${this.baseUrl}/?date=${dateStr}`);
  }
}
