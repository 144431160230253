import {Injectable} from '@angular/core';
import {ScheduledStudent} from "../../models/core/scheduled-student";
import {SortDirection} from "../../../util/sorting/sorting";
import {PageableList} from "../../../util/paging/pageable-list";
import {AbstractPageableAdapterService} from "../../../core/services/abstract-pageable-adapter.service";
import {Observable} from "rxjs";

const apiUrl: string = '/api/pageable/scheduledStudent';

@Injectable({
  providedIn: 'root'
})
export class ScheduledStudentPageableAdapterService extends AbstractPageableAdapterService<ScheduledStudent> {

  protected override getApiUrl(): string {
    return apiUrl;
  }

  findByScheduleIdPaged(scheduleId: number, page?: number, pageSize?: number, sortKey?: string,
                        direction?: SortDirection): Observable<PageableList<ScheduledStudent>> {
    let additionalParams = new Map<string, string>();
    additionalParams.set('scheduleId', scheduleId.toString());
    return super.findAllPagedInternal(page, pageSize, sortKey, direction, additionalParams);
  }

}
